import axios from "axios";
import { API_URL } from "constants";

export const createAddress = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/addresses`;
  return axios.post(url, formData).then((response) => response.data);
};

export const updateAddress = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/addresses/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const fetchAddressById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/addresses/${id}`;
  return axios.get(url).then((response) => response.data);
};

export const deleteAddress = async (id, companyId) => {
  const url = `${API_URL}/${companyId}/addresses/${id}`;
  return axios.delete(url).then((response) => response.data);
};

export const fetchAddresses = (companyId, subjectId) => {
  const url = `${API_URL}/${companyId}/addresses/subject/${subjectId}`;
  return axios.get(url).then((response) => response.data);
};
