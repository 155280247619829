import { useEffect } from "react";
import { isMobile, isBrowser } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import {
  Layout,
  Card,
  Filters,
  ResourceList,
  Avatar,
  ResourceItem,
  Text,
  Select
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams, Outlet } from "react-router-dom";
import { RootState, AppDispatch } from "configureStore";
import { fetchCustomers, setFilterValue } from "./customers.slice";
import { setModalxComponent } from "components/modalx/modalx.slice";
import Currency from "components/format/currency/currency";
import UserSelect from "features/user-select/user-select";
import DataTable from "components/datatable/Datatable";
import { assignCustomerToUser } from "services/user-select.service";

type FiltersValue = {
  textSearch?: string | null;
  user?: string | null;
  access?: string | null;
};

type Customer = {
  id: string;
  name: string;
  assignedUser?: { name: string };
  _overdueDebt: number;
  _turnover: number;
};

function Customers() {
  const dispatch : AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId, userId } = useParams<{ companyId: string; userId?: string }>();

  const { customersCollection, filtersValue } = useSelector(
    (state: RootState) => state.customerAccessReducer
  );

  const users: Record<string, string> = useSelector(
    (state: RootState) => state.customerAccessReducer.users || {}
  );

  const handleCreateCustomer = () => {
    dispatch(setModalxComponent("CustomerForm"));
  };

  const disambiguateLabel = (key: keyof FiltersValue, value: string) => {
    switch (key) {
      case "textSearch":
        return `${t("search")}: ${t(value)}`;
      case "user":
        return `${t("assigned_user")} ${users[value]}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    if(companyId){
      dispatch(fetchCustomers(companyId, filtersValue, userId!));
    }
  }, [dispatch, filtersValue, companyId, userId]);

  const handleFilterChange = (value: any, key: keyof FiltersValue) => {
    dispatch(setFilterValue({ key, value}));
  };
  const handleQueryChange = (value: string) => {
    dispatch(setFilterValue({ key: "textSearch", value }));
  };
  const handleQueryValueRemove = () => {
    dispatch(setFilterValue({ key: "textSearch", value: "" }));
  };
  const handleClearAll = () => {};

  const handleCheckboxClick = async(item:any) => {
    const payload = {
        user: userId, 
        customer: item.id
    }
    await assignCustomerToUser(companyId, payload);
    if(companyId && userId){
        dispatch(fetchCustomers(companyId, filtersValue, userId!));
    }
  }

  const appliedFilters = Object.keys(filtersValue).reduce<{ key: string; label: string; onRemove: () => void }[]>(
    (acc, key) => {
      const value = filtersValue[key as keyof FiltersValue];
      if (value && value.length > 0) {
        acc.push({
          key,
          label: disambiguateLabel(key as keyof FiltersValue, value),
          onRemove: () => handleFilterChange(null, key as keyof FiltersValue),
        });
      }
      return acc;
    },
    []
  );

  const filters = [
    {
      key: "access",
      label: t("access"),
      filter: (
         <Select
            label="access"
            options={[
                {label: '-', value: '' },
                {label: 'checked', value: 'checked'},
            ]}
            onChange={(val) => handleFilterChange(val, "access")}
            value={filtersValue.access || ""}
          />
      ),
    },
    {
      key: "user",
      label: t("assigned_user"),
      filter: (
        <UserSelect
          value={filtersValue.user || ""}
          name="user"
          onChange={handleFilterChange}
          isRequired={false}
          allowMultiple={true}
          label={"select_user"}
        />
      ),
    },
  ];

  let content = <Outlet />;

  const filtersNode = (
    <Filters
      queryValue={filtersValue.textSearch || ""}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    />
  );


  content = (
    <Card>
      {isMobile && (
        <ResourceList
          resourceName={{ singular: t("customer"), plural: t("customers") }}
          items={customersCollection}
          filterControl={filtersNode}
          flushFilters
          renderItem={(item: Customer) => {
            const { id, assignedUser, name, _overdueDebt, _turnover } = item;
            const media = <Avatar customer size="md" name={name} />;

            return (
              <ResourceItem id={id} url={id} media={media} accessibilityLabel={`View details for ${name}`}>
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {name}
                </Text>
                <div>{assignedUser?.name || "-"}</div>
                <div className="flex justify-space">
                  <div>
                    {_turnover > 0 && (
                      <Text as="p" alignment="start">
                        <Currency isSymbol={true} value={_turnover} />
                      </Text>
                    )}
                  </div>
                  <div>
                    {_overdueDebt > 0 && (
                      <Text as="p" alignment="end">
                        {t("debt")}: <Currency isSymbol={true} value={_overdueDebt} />
                      </Text>
                    )}
                  </div>
                </div>
              </ResourceItem>
            );
          }}
        />
      )}
      {isBrowser && (
        <div>
          <div style={{ padding: "16px", display: "flex" }}>
            <div style={{ flex: 1 }}>{filtersNode}</div>
          </div>
          <DataTable
            collection={customersCollection}
            columns={[
              { key: "#" },
              { key: "isPermission", name: t("access"), type: "checkbox", onClick: (item: any) => handleCheckboxClick(item)},
              { key: "name", name: t("title") },
              { key: "externalCode", name: t("external_code") },
              { key: "assignedUser.name", name: t("assigned_user") },
              { key: "_turnover", type: "currency", name: t("turnoverWithoutVat") },
              { key: "_debt", type: "currency", name: t("debtWithVat") },
              { key: "_overdueDebt", type: "currency", name: t("overdue_debt") },
            ]}
          />
        </div>
      )}
    </Card>
  );


  return (
    <Layout>
      <Layout.Section>{content}</Layout.Section>
    </Layout>
  );
}

export default Customers;
