import { useEffect, useState } from "react";
import { Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchUserByPermission } from "services/user-select.service";

interface UserSelectProps {
  value?: string;
  onChange: (selectedValue: string) => void;
  isRequired?: boolean;
  isDisable?: boolean;
  label?: string;
  Permission?: string;
  error?:string;
}

interface User {
  id: string;
  name: string;
  email: string;
}

const UserSelect: React.FC<UserSelectProps> = ({
  value,
  onChange,
  isRequired = false,
  isDisable = false,
  label = "select_sign_permission_user",
  Permission = "Signature",
  error:propsErr=""
}) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useState<
    { label: string; value: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams<{ companyId: string }>();
  const [error, setError] = useState("");

  const handleChange = (selectedValue: string) => {
    if (isRequired && !selectedValue) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }

    onChange(selectedValue);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data = await fetchUserByPermission(companyId!, Permission);
        const dataCollection = data.usersCollection.map((row: User) => ({
          label: row.name || row.email,
          value: row.id,
        }));
        setCollection(dataCollection);
        onChange(dataCollection[0].value);
      } catch (e) {
        console.error("Error fetching user collection:", e);
      } finally {
        setIsLoading(false);
      }
    }

    if (companyId) {
      fetchData();
    }
  }, [companyId]);

  return (
    <div className="user-select">
      <Select
        label={t(label)}
        options={collection}
        value={value}
        disabled={isLoading || isDisable}
        onChange={handleChange}
        error={error || propsErr}
        requiredIndicator={isRequired}
      />
    </div>
  );
};

export default UserSelect;
