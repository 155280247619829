import { useState, useEffect, useCallback } from "react";
import { API_URL } from "../../../constants";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
    FormLayout,
    Banner,
    Form,
    Button,
    TextField,
    Select,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import FileTemplateSelect from "components/file-template-select/file-template-select";
import { setIsModalxOpen } from "components/modalx/modalx.slice";
import { CustomField } from "types/CustomField";
import DatePickerx from "features/datepicker/date-picker";
import { Product } from "types/Product";
import { useAppDispatch } from "hooks";
import { fetchFileTemplateById } from "containers/files-template-form/files-template-form.service";
import ProductsSelect from "components/products-select/ProductsSelect";
import { fetchFileDetails } from "containers/file/file.Slice";
import { Address } from "types/Address";
import AddressSelectMultiple from "features/customer-address-select-multiple/customer-address-select-multiple";
import CustomerContactSelect from "features/customer-contact-select/customer-contact-select";
import UserSelectByPermissions from "features/user-select-by-permission/user-select-by-permission";
import UserSelect from "features/user-select/user-select";
import { fetchById } from "services/upload-file.service";
import AddressCreateForm from "containers/address-create/address-create";

interface onSubmitData {
    templateId: string;
    date: string;
    customFields: { [key: string]: string };
    products: Product[];
    fileId?: string;
    signersInfo?: {};
    address?: string[];
    fileTypeContext?: string;
}

interface PostData extends onSubmitData {
    customerId: string;
}

function BuildPdfFormWithSigners({
    customerId,
    isCustomerApproved = false,
    template = "",
    fileId = "",
    assignedUserId = "",
    id = ""
}: {
    customerId: string;
    isCustomerApproved: boolean;
    template: string;
    fileId: string;
    signersInfo: any;
    assignedUserId: string;
    id: string;
}) {
    const { t } = useTranslation();
    const [templateId, setTemplateId] = useState("");
    const [date, setDate] = useState<string>(new Date().toISOString().split("T")[0]);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const { companyId } = useParams();
    const dispatch = useAppDispatch();
    const [selectedUser, setSelectedUser] = useState("");
    const [contactSigner, setContactSigner] = useState("");
    const [signType, setSignType] = useState("Dokobit");
    const [selectedNonTechUser, setSelectedNonTechUser] = useState<any>(
        assignedUserId || ""
    );
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [fileTypeContext, setFileTypeContext] = useState("");
    const [templateIdByFetch, setTemplateIdByFetch] = useState("");
    

    const signCollection = [
        { label: t("manual_sign"), value: "Manual" },
        { label: "Dokobit", value: "Dokobit" },
    ];

    const handleSignType = (value: string) => {
        setSignType(value);
    };

    const isFormValid = () => {
        let validationErrors: { [key: string]: string } = {};
    
        if (!templateId) {
            validationErrors["templateId"] = t("Template is required");
        }
        if (!date) {
            validationErrors["date"] = t("Date is required");
        }
        if (!contactSigner) {
            validationErrors["contactSigner"] = t("Contact signer is required");
        }
        if (!signType) {
            validationErrors["signType"] = t("Sign type is required");
        }
        if (!selectedUser) {
            validationErrors["selectedUser"] = t("Selected user is required");
        }
        if (selectedUser === selectedNonTechUser) {
            validationErrors["selectedUserMatch"] = t("Contact user and permission user cannot be the same!");
        }
        // Check custom fields
        customFields.forEach((field) => {
            if (field.required && (!field.value || field.value?.length === 0)) {
                validationErrors[field.label] = t(`${field.label} is required`);
            }
        });
    
        setErrors(validationErrors);
        
        return Object.keys(validationErrors).length === 0; // Return false if errors exist
    };

    const transformCustomFields = (apiCustomFields: Record<string, any>) => {
        const transformedFields: Record<string, any> = {};

        Object.entries(apiCustomFields).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                // Extract only `id` values from array
                transformedFields[key] = value.map((entity) => entity?.id);
            } else {
                transformedFields[key] = value;
            }
        });

        return transformedFields;
    };

    const fetchCustomFields = useCallback(
        async (templateId: string) => {
            try {
                const fileTemplateEntity = await fetchFileTemplateById(
                    companyId,
                    templateId
                );
                setCustomFields(
                    fileTemplateEntity.customFields.map((field: CustomField) => ({
                        ...field,
                        value:''
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        },
        [companyId]
    );

    useEffect(() => {
        async function fetchData() {
            try {
                const {
                    name,
                    dateTo,
                    relatedTo,
                    date,
                    assignedUser,
                    templateId:templateId_,
                    userSigner,
                    contactSigner,
                    permissionUserSigner,
                    signType,
                    filesTemplateCustomField,
                    fileTypeContext
                } = await fetchById(id, companyId);
                if(!templateId) {
                    setTemplateId(templateId_?.id);
                }
                setSignType(signType);
                setContactSigner(contactSigner);
                setSelectedUser(permissionUserSigner);
                setSelectedNonTechUser(userSigner);
                setDate(date);
                setFileTypeContext(fileTypeContext);
                const transformedFields = transformCustomFields(filesTemplateCustomField?.customFields);
                if(relatedTo?.templateId) setTemplateIdByFetch(relatedTo.templateId);
                
                if(templateId){
                    const fileTemplateEntity = await fetchFileTemplateById(
                        companyId,
                        templateId 
                    );

                    setCustomFields(
                        fileTemplateEntity.customFields.map((field: CustomField) => ({
                            ...field,
                            value:transformedFields[field.label] || ""
                        }))
                    );
                    setProducts(filesTemplateCustomField?.products || []);
                }

            } catch (e) {
                // console.error(e);
            }
        }
        if (id) {
            fetchData();
        }
    }, [id, companyId, customFields.length, templateId]);
    useEffect(() => {
        if (templateId && !id) {
            fetchCustomFields(templateId);
        }
    }, [templateId, fetchCustomFields]);
    

    const handleCustomFieldChange = (index: number, value: string) => {
        const updatedFields = customFields.map((field, i) =>
            i === index ? { ...field, value } : field
        );
        setCustomFields(updatedFields);
    };

    const getDatePart = (dateString: string) => {
        if (dateString.includes("T")) {
            return dateString.split("T")[0];
        }
        return dateString; 
    };

    const handleFormSubmit = async () => {
        if (!isFormValid()) {
            return;
        }
        const convertedDate = getDatePart(date);
        const formData: PostData = {
            date: convertedDate,
            templateId,
            customerId,
            customFields: {},
            products,
            fileId,
            signersInfo: {
                contactSigner,
                companyUserSigner: selectedUser,
                nontechuser: selectedNonTechUser,
                signType
            },
            fileTypeContext
        };
        customFields.forEach((field) => {
            formData.customFields[field.label] = field.value;
        });

        if (companyId) {
            try {
                setIsLoading(true);
                const action = id ? update : submit;
                const fileEntity = await action(companyId, formData, id);
                if (fileId) dispatch(fetchFileDetails({ fileId, companyId }));
                if (fileEntity.id) {
                    dispatch(fetchCustomer({ companyId, customerId }));
                    setIsSuccessful(true);
                    setTimeout(() => {
                        dispatch(setIsModalxOpen(false));
                    }, 1500);
                } else {
                    setIsFailed(true);
                    setIsLoading(false);
                }
            } catch (e) {
                setIsFailed(true);
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="user-form">
            <Form onSubmit={handleFormSubmit}>
                <div className="form-warnings">
                    {isFailed && (
                        <div className="">
                            <Banner tone="critical">{t("error")}</Banner>
                        </div>
                    )}
                    {isSuccessful && (
                        <div className="">
                            <Banner tone="info">{t("ok")}</Banner>
                        </div>
                    )}
                </div>
                <FormLayout>
                    <FileTemplateSelect
                        onChange={setTemplateId}
                        value={templateId}
                        isRequired={true}
                        isCustomerApproved={isCustomerApproved}
                        templateId={template || templateIdByFetch}
                        error={errors["templateId"]}
                    />
                    <TextField
                        label={t("file_context")}
                        type="text"
                        autoComplete="off"
                        value={fileTypeContext}
                        onChange={setFileTypeContext}
                        labelHidden
                        placeholder={t("enter_file_context")}
                    />
                    
                    {templateId && <DatePickerx
                        disableDatesAfter={null}
                        title={t("document_date")}
                        isRequired={true}
                        onChange={(value: any) =>
                            setDate(value.toISOString().split("T")[0])
                        }
                        selected={date}
                    />}
                    {customFields.map((field, index) => (
                        <div key={field.id}>
                            {field.type === "string" && (
                                <TextField
                                    label={field.name}
                                    value={field.value}
                                    autoComplete="off"
                                    onChange={(value: string) =>
                                        handleCustomFieldChange(index, value)
                                    }
                                    requiredIndicator={field.required}
                                    error={errors[field.label]}
                                />
                            )}
                            {field.type === "textarea" && (
                                <TextField
                                    label={field.name}
                                    value={field.value}
                                    multiline={4}
                                    autoComplete="off"
                                    onChange={(value: string) =>
                                        handleCustomFieldChange(index, value)
                                    }
                                    requiredIndicator={field.required}
                                    error={errors[field.label]}
                                />
                            )}
                            {field.type === "date" && (
                                <DatePickerx
                                    disableDatesAfter={null}
                                    title={field.name}
                                    isRequired={field.required}
                                    onChange={(value: any) =>
                                        handleCustomFieldChange(
                                            index,
                                            value.toISOString().split("T")[0]
                                        )
                                    }
                                    selected={field.value}
                                />
                            )}
                            {field.type === "select" && (
                                <Select
                                    label={field.name}
                                    options={[
                                        {
                                            label: t(
                                                "files_template_custom_type_select_select_an_option"
                                            ),
                                            value: "",
                                        },
                                        ...field.options.map((option) => ({
                                            label: option,
                                            value: option,
                                        })),
                                    ]}
                                    value={field.value}
                                    onChange={(value: string) =>
                                        handleCustomFieldChange(index, value)
                                    }
                                    requiredIndicator={field.required}
                                    error={errors[field.label]}
                                />
                            )}
                            {field.type === "products" && (
                                <ProductsSelect
                                    onChange={(value: any) => {
                                        if (
                                        JSON.stringify(products) !== JSON.stringify(value)
                                        ) {
                                        handleCustomFieldChange(index, value);
                                        setProducts(value);
                                        }
                                    }}
                                    initialProducts={products}
                                    isRequired={field.required}
                                />
                            )}
                            {field.type === "address" && (
                                <>  
                                    <AddressCreateForm/>
                                    <AddressSelectMultiple
                                        value={Array.isArray(field.value) ? field.value.filter(Boolean) : field.value ? [field.value] : []}
                                        onChange={(value: any) => {
                                            handleCustomFieldChange(index, value);
                                        }}
                                        customerId={customerId}
                                        isRequired={field.required}
                                    />
                                </>
                            )}
                        </div>
                    ))}

                    {templateId &&
                        <div style={{ marginBottom: "10px" }}>
                            <CustomerContactSelect
                                label={"select_contact"}
                                onChange={setContactSigner}
                                value={contactSigner}
                                customerId={customerId}
                                isRequired={true}
                                error={errors["contactSigner"]}
                            />

                            <UserSelectByPermissions
                                value={selectedUser}
                                onChange={setSelectedUser}
                                isRequired={true}
                                Permission={"Signature"}
                                error={errors["selectedUser"]}
                            />

                            <UserSelect
                                onChange={setSelectedNonTechUser}
                                value={selectedNonTechUser}
                                label={"select_contact_user"}
                            />
                            <Select
                                label={t("sign_type")}
                                options={signCollection}
                                value={signType}
                                onChange={handleSignType}
                                requiredIndicator={true}
                                error={errors["signType"]}
                            />
                        </div>
                    }
                    {selectedUser == selectedNonTechUser && (
                        <div className="">
                            <Banner tone="critical">{t("contact_user_and_permission_user_can_not_be_same!")}</Banner>
                        </div>
                    )}
                    <Button loading={isLoading} submit>
                        {t("save")}
                    </Button>
                </FormLayout>
            </Form>
        </div>
    );
}

export default BuildPdfFormWithSigners;

function submit(companyId: string, data: PostData) {
    const url = `${API_URL}/${companyId}/files/build-pdf`;
    return axios.post(url, data).then((response) => response.data);
}

function update(companyId: string, data: PostData, fileid: string) {
    const url = `${API_URL}/${companyId}/files/build-pdf/${fileid}`;
    return axios.patch(url, data).then((response) => response.data);
}
