import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Checkbox,
  Banner,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchById } from "services/user-select.service";
import UploadFile from "../upload-file/upload-file";
import Files from "../files/files";
import { submit } from "./user-form.slice";
import PermissionsGroupsSelect from "components/permissions-groups-select/permissions-groups-select";
import UserSelect from "features/user-select/user-select";
import { fetchRole } from "features/auth/authSlice";

function UserForm({ id: userId }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [viewFiles, setViewFiles] = useState();
  const [files, setFiles] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [externalCode, setExternalCode] = useState();
  const [groups, setGroups] = useState([]);
  const [manager, setManager] = useState();
  const [isUserTechnical, setIsUserTechnical] = useState(false);
  const [isEnableCategoryRules, setIsEnableCategoryRules] = useState(false);
  const [companyPhone, setCompanyPhone] = useState("");
  const [position, setPosition] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const { t } = useTranslation();
  const { companyId } = useParams();
  const { isLoading } = useSelector((state) => state.userFormReducer);

  // const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async () => {
    const formData = {
      userId,
      email,
      files,
      isDisabled,
      name,
      phone,
      externalCode,
      company: companyId,
      groups,
      manager,
      isUserTechnical,
      companyPhone,
      position,
      isEnableCategoryRules
    };

    await dispatch(submit(companyId, formData));
    if (companyId) dispatch(fetchRole(companyId));
  };
  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handleIsDisabledChange = useCallback(
    (value) => setIsDisabled(value),
    []
  );

  const handleIsUserTechnicalChange = useCallback(
    (value) => setIsUserTechnical(value),
    []
  );

  const handleEnableCategoryRules = useCallback(
    (value) => setIsEnableCategoryRules(value),
    []
  );

  const handleFilesChange = useCallback((value) => setFiles(value), []);
  const isFormValid = () => email;

  useEffect(() => {
    async function fetchData() {
      try {
        const { userEntity } = await fetchById(userId, companyId);
        setEmail(userEntity.email);
        setViewFiles(userEntity.files);
        setIsDisabled(!!userEntity.isDisabled);
        setGroups(userEntity.usersGroups.map((entity) => entity.id));
        setManager(userEntity.manager.id);
        setIsUserTechnical(!!userEntity.isUserTechnical);
        setPosition(userEntity.position);
        setCompanyPhone(userEntity.companyPhone);
        setIsEnableCategoryRules(!!userEntity.isEnableCategoryRules)
      } catch (e) {
        // console.error(e);
      }
    }
    if (userId) {
      fetchData();
    }
  }, [userId, companyId]);

  const managerHandler = (id) => {
    if (id != userId) {
      setManager(id);
      setErrMsg("");
    } else {
      setErrMsg("you_can_not_select_same_user_as_manager");
    }
  };

  const filesNode = viewFiles ? <Files files={viewFiles} /> : "";
  const filesForm = userId ? (
    <div>
      {filesNode}
      <UploadFile onChange={handleFilesChange} />
    </div>
  ) : (
    ""
  );

  const submitNode = userId ? (
    <Button loading={isLoading} disabled={!isFormValid()} submit>
      {t("update_user")}
    </Button>
  ) : (
    <Button loading={isLoading} disabled={!isFormValid()} submit>
      {t("create_user")}
    </Button>
  );

  useEffect(() => {
    console.log("isDisabled updated:", isDisabled);
    console.log("isUserTechnical updated:", isUserTechnical);
    console.log("isEnableCategoryRules updated:", isEnableCategoryRules);
  }, [isUserTechnical, isEnableCategoryRules, isDisabled]);

  return (
    <div className="user-form">
      <Form className="user-form_form" onSubmit={handleFormSubmit}>
        <FormLayout>
          <div className="form-warnings">
            {errMsg && (
              <div className="">
                <Banner tone="critical">{t(errMsg)}</Banner>
              </div>
            )}
          </div>
          <TextField
            disabled={userId}
            label={t("Email")}
            type="email"
            requiredIndicator
            autoComplete="off"
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            disabled={userId}
            label={t("name")}
            type="text"
            autoComplete="off"
            value={name}
            onChange={setName}
          />
          <TextField
            disabled={userId}
            label={t("phone")}
            type="text"
            autoComplete="off"
            value={phone}
            onChange={setPhone}
          />
          <TextField
            disabled={userId}
            label={t("external_code")}
            type="text"
            autoComplete="off"
            value={externalCode}
            onChange={setExternalCode}
          />
          <TextField
            label={t("company_phone")}
            type="text"
            autoComplete="off"
            value={companyPhone}
            onChange={setCompanyPhone}
          />
          <TextField
            label={t("position")}
            type="text"
            autoComplete="off"
            value={position}
            onChange={setPosition}
          />
          <UserSelect
            onChange={managerHandler}
            value={manager}
            label={"select_manager"}
          />
          <PermissionsGroupsSelect onChange={setGroups} value={groups} />
          <Checkbox
            label={t("disabled")}
            checked={!!isDisabled}
            onChange={handleIsDisabledChange}
          />
          <Checkbox
            label={t("is_user_technical")}
            checked={!!isUserTechnical}
            onChange={handleIsUserTechnicalChange}
          />
          <Checkbox
            label={t("enable_category_tree_rule")}
            checked={!!isEnableCategoryRules}
            onChange={handleEnableCategoryRules}
          />
          {filesForm}
          {submitNode}
        </FormLayout>
      </Form>
    </div>
  );
}

export default UserForm;
