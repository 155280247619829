import { useTranslation } from "react-i18next";
import {
  BlockStack,
  Button,
  Card,
  InlineGrid,
  Text,
  ResourceList,
  ResourceItem,
} from "@shopify/polaris";
import { Address } from "types/Address";
import { PlusIcon } from "@shopify/polaris-icons";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import { useAppDispatch } from "hooks";

type Props = {
  addresses: Address[];
  subject: string;
  subjectId: string;
};

const Addresses = ({ addresses, subject, subjectId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleContactCreate = () => {
    dispatch(setModalxComponent("AddressForm"));
    dispatch(setModalxComponentProps({ subjectId, subject }));
  };

  const handleContactEdit = (id: string) => {
    dispatch(setModalxComponent("AddressForm"));
    dispatch(setModalxComponentProps({ subjectId, id, subject }));
  };

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="200">
        <InlineGrid columns="1fr auto">
          <Text as="h2" variant="headingSm">
            {t(`addresses`)}
          </Text>
          <Button
            onClick={handleContactCreate}
            accessibilityLabel={t("address_add")}
            icon={PlusIcon}
          >
            {t(`address_add`)}
          </Button>
        </InlineGrid>
        <ResourceList
          resourceName={{ singular: t(`address`), plural: t(`addresses`) }}
          items={addresses}
          renderItem={(item) => {
            const { id, name, address } = item;

            return (
              <ResourceItem
                id={id}
                onClick={handleContactEdit}
                accessibilityLabel={`View details for ${name}`}
              >
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {name}
                </Text>
                <Text variant="bodyMd" fontWeight="regular" as="p">
                  {address}
                </Text>
              </ResourceItem>
            );
          }}
        />
      </BlockStack>
    </Card>
  );
};

export default Addresses;
