import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetch as fetchUsers } from "services/user-select.service";
import { fetchCustomersList } from "services/customers.service";
import { AppDispatch, RootState } from "configureStore";

interface User {
  id: string;
  name: string;
}

interface FiltersValue {
  [key: string]: string;
}

interface CustomersState {
  customersCollection: any[];
  isLoading: boolean;
  isError: boolean;
  errMsg: string | null;
  queryValue: string | null;
  users: Record<string, string>;
  filtersValue: FiltersValue;
}

const initialState: CustomersState = {
  customersCollection: [],
  isLoading: false,
  isError: false,
  errMsg: null,
  queryValue: null,
  users: {},
  filtersValue: {},
};

const CustomersSlice = createSlice({
  name: "customers-access",
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setCustomers(state, action: PayloadAction<{ customersCollection: any[] }>) {
      state.isLoading = false;
      state.customersCollection = action.payload.customersCollection;
    },
    setUsers(state, action: PayloadAction<{ usersCollection: User[] }>) {
      action.payload.usersCollection.forEach(({ id, name }) => {
        state.users[id] = name;
      });
    },
    setError(state, action: PayloadAction<{ errMsg: string }>) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
    setFilterValue(
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) {
      state.filtersValue[action.payload.key] = action.payload.value;
    },
  },
});

export const { setLoading, setCustomers, setUsers, setError, setFilterValue } =
  CustomersSlice.actions;

export default CustomersSlice.reducer;

export const fetchCustomers = (
  companyId: string,
  filtersValue: FiltersValue,
  userId: string
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading());
  try {
    const { customersCollection } = await fetchCustomersList(
      companyId,
      filtersValue,
      userId
    );
    dispatch(setCustomers({ customersCollection }));

    const { usersCollection } = await fetchUsers(companyId);
    dispatch(setUsers({ usersCollection }));
  } catch (e) {
    dispatch(setError({ errMsg: "An error occurred" }));
  }
};
