import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFilesCategories } from "services/files-category.service";

interface DocumentContainerState {
  isLoading: boolean;
  isError: boolean;
  errMsg: string;
  categoriesData: any | null;
}

const initialState: DocumentContainerState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  categoriesData: null,
};

// Create the slice
const documentContainerSlice = createSlice({
  name: "Documents-permissions",
  initialState,
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state: DocumentContainerState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(
      state: DocumentContainerState,
      action: PayloadAction<{ error: string }>
    ) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setCategoryContainer(
      state: DocumentContainerState,
      action: PayloadAction<any>
    ) {
      state.categoriesData = action.payload;
      state.isLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = documentContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setCategoryContainer } =
  actions;

// Export the reducer, either as a default or named export
export default reducer;

// Define the type for the dispatch function
export const fetchCategories =
  ({ companyId, userId }: { companyId: string, userId: string }) =>
    async (dispatch: any) => {
      dispatch(setLoading(true));
      try {
        const data = await fetchFilesCategories(companyId, userId);
        dispatch(setCategoryContainer(data));
      } catch (e) {
        const error = "not_fetch";
        dispatch(setError({ error }));
        return false;
      }
    };
