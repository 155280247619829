/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
import React from "react";
import PropTypes from "prop-types";
import { Props, LocalesArguments } from "./types";
const EMPTY_VALUE = "-";

export default function Currency({ isDecimal = false, value = 0, isSymbol = false }: Props) {
  const formatValue = getValue(value, isSymbol, isDecimal);
  const title = value !== null && value !== undefined ? value.toString() : EMPTY_VALUE;

  return (
    <span className="currency" title={title}>
      {formatValue}
    </span>
  );
}

export function getValue(value: number | null | undefined, isSymbol: boolean, isDecimal: boolean) {
  if (value === null || value === undefined) {
    return EMPTY_VALUE;
  }

  const options: LocalesArguments = {
    minimumFractionDigits: isDecimal ? 2 : 0,
    maximumFractionDigits: isDecimal ? 2 : 2,
    ...(isSymbol && { style: "currency", currency: "EUR" }), 
  };

  
  let formattedValue = new Intl.NumberFormat("en-US", options).format(value);

  if (isDecimal) {
    formattedValue = parseFloat(value.toFixed(2)).toLocaleString("en-US", options);
  }

  return formattedValue;
}

Currency.propTypes = {
  isDecimal: PropTypes.bool,
  value: PropTypes.number,
  isSymbol: PropTypes.bool,
};

Currency.defaultProps = {
  isDecimal: false,
  isSymbol: false,
  value: 0,
};
