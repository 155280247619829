import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAddresses } from "services/address.service";
import { Address } from "types/Address";

interface AddressState {
  addressesCollection: Address[];
  isLoading: boolean;
  isError: boolean;
  errMsg: string | null;
}

const initialState: AddressState = {
  addressesCollection: [],
  isLoading: false,
  isError: false,
  errMsg: null,
};

const AddressSlice = createSlice({
  name: "get-address-list",
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setAddress(state, action: PayloadAction<{ addressesCollection: Address[] }>) {
      state.isLoading = false;
      state.addressesCollection = action.payload.addressesCollection;
    },
    setError(state, action: PayloadAction<{ errMsg: string }>) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
  },
});

export const { setLoading, setAddress, setError } = AddressSlice.actions;
export default AddressSlice.reducer;

export const fetchAddressList =
  (companyId: string, customerId?: string) => async (dispatch: any) => {
    dispatch(setLoading());
    try {
      const { addressesCollection } = await fetchAddresses(companyId, customerId);
      dispatch(setAddress({ addressesCollection }));
    } catch (e) {
      dispatch(setError({ errMsg: "Failed to fetch addresses" }));
    }
  };
