import axios from "axios";
import { API_URL } from "../constants";

export const fetch = (companyId, filtersValue) => {
  const url = `${API_URL}/${companyId}/customers/fetch`;
  return axios
    .post(url, { filtersValue, companyId })
    .then((response) => response.data);
};

export const post = (companyId, data) => {
  const url = `${API_URL}/${companyId}/customers`;
  return axios.post(url, data).then((response) => response.data);
};

export const fetchById = (companyId, customerId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}`;
  return axios.get(url).then((response) => response.data);
};

export const sendCreateCustomerQuestionnaire = (companyId, customerId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/post-questionnaire`;
  return axios.post(url).then((response) => response.data);
};

export const RequestCustomerApproval = (companyId, customerId, data) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/approve`;
  return axios.post(url, data).then((response) => response.data);
};

export const fetchContacts = (companyId, customerId) => {
  const url = `${API_URL}/companies/${companyId}/customers/${customerId}/contacts?signed=true`;
  return axios.get(url).then((response) => response.data);
};

export const postFinance = (companyId, customerId, data) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/finance/sign-request`;
  return axios.post(url, data).then((response) => response.data);
};

export const fetchCustomersList = (companyId, filtersValue, userId) => {
  const url = `${API_URL}/${companyId}/customers/fetch/${userId}`;
  return axios
    .post(url, { filtersValue, companyId })
    .then((response) => response.data);
};
export const cancelCustomerQuestionnaire = (companyId, customerId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/cancel-questionnaire`;
  return axios.post(url).then((response) => response.data);
}; 
