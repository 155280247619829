import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "configureStore";
import { Button, InlineStack, Banner, Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { postFinance } from "services/customers.service";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import { setDestroy, setIsModalxOpen } from "components/modalx/modalx.slice";
import CustomerContactSelect from "features/customer-contact-select/customer-contact-select";
import UserSelectByPermissions from "features/user-select-by-permission/user-select-by-permission";
import DatePickerx from "features/datepicker/date-picker";
import UserSelect from "features/user-select/user-select";

interface ConfirmModalProps {
  companyId: string;
  customerId: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  companyId,
  customerId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { customer } = useSelector((state: any) => state.customerReducer);
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [date, setDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [contactSigner, setContactSigner] = useState("");
  const [signType, setSignType] = useState("Dokobit");
  const [selectedNonTechUser, setSelectedNonTechUser] = useState<any>(
    customer?.assignedUser?.id || ""
  );
  const handleSignType = (value: string) => {
    setSignType(value);
  };

  const handleGenerateFinancialForm = async () => {
    try {
      setIsLoading(true);
      const formData = {
        date,
        user: selectedUser,
        contactId: contactSigner,
        signType,
        nontechuser: selectedNonTechUser,
      };
      await postFinance(companyId, customerId, formData);
      dispatch(fetchCustomer({ customerId, companyId: companyId! }));
      setSuccessMessage("ok");
      setIsLoading(false);
      closeModal();
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    dispatch(setIsModalxOpen(false));
    dispatch(setDestroy());
  };

  const signCollection = [
    { label: t("manual_sign"), value: "Manual" },
    { label: "Dokobit", value: "Dokobit" },
  ];

  return (
    <>
      <div className="form-warnings">
        {errMsg && (
          <div>
            <Banner tone="critical">{t(errMsg)}</Banner>
          </div>
        )}
        {successMessage && (
          <div>
            <Banner tone="info">{t(successMessage)}</Banner>
          </div>
        )}
      </div>
      <h1 style={{ textAlign: "center" }}>
        {t(
          "Are_you_sure_you_want_to_generate_and_send_finance_file_for_customer_sign"
        )}
      </h1>
      <br />
      <br />
      <div style={{ marginBottom: "10px" }}>
        <DatePickerx
          disableDatesAfter={null}
          title={t("document_date")}
          isRequired={true}
          onChange={(value: any) =>
            setDate(value.toISOString().split("T")[0])
          }
          selected={date}
        />
        <br />
        <CustomerContactSelect
          label={"select_contact"}
          onChange={setContactSigner}
          value={contactSigner}
          customerId={customerId}
          isRequired={true}
        />
        <br />
        <UserSelectByPermissions
          onChange={setSelectedUser}
          value={selectedUser}
          isRequired={true}
          Permission={"Signature"}
        />
        <br />
        <UserSelect
          onChange={setSelectedNonTechUser}
          value={selectedNonTechUser}
          label={"select_contact_user"}
        />
        <br />
        <Select
          label={t("sign_type")}
          options={signCollection}
          value={signType}
          onChange={handleSignType}
          requiredIndicator={true}
        />
      </div>
      <InlineStack align="space-between">
        <Button
          onClick={handleGenerateFinancialForm}
          loading={isLoading}
          disabled={!selectedUser || !contactSigner}
        >
          {t("Yes")}
        </Button>

        <Button onClick={closeModal}>{t("No")}</Button>
      </InlineStack>
    </>
  );
};

export default ConfirmModal;
