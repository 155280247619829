import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "configureStore";
import {
  Layout,
  Page,
  Card,
  Text,
  BlockStack,
  TextContainer,
  Button,
  ButtonGroup,
  InlineStack,
  EmptyState,
  Spinner,
  Tabs
} from "@shopify/polaris";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UploadFile from "features/upload-file/upload-file";
import Files from "features/files/files";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import {
  fetchUserDetails,
  setDestroy,
} from "containers/user/user-container.slice";
import { getAuthCodeUrl, disconnectMicrosoftSync } from "services/user-select.service";
import FilesCategoriesTree from "containers/document/category-permissions-tree/category-tree";
import Customers from "containers/customers-access-list/customers";


function UserContainer() {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { userId, companyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const loadUserData = useCallback(() => {
    if (userId && companyId) {
      dispatch(fetchUserDetails({ userId, companyId }));
    }
  }, [userId, companyId, dispatch]);

  const { userEntity, isLoading } = useSelector(
    (state: RootState) => state.userContainerReducer
  );

  useEffect(() => {
    loadUserData();
    return () => {
      dispatch(setDestroy());
    };
  }, [loadUserData]);

  const handleOnFileChange = () => {};

  const handleEditClick = (id: string) => {
    dispatch(setModalxComponent("UserForm"));
    dispatch(setModalxComponentProps({ id }));
  };

  const handleAuthCodeUrl = async (id: string) => {
    const authCodeUrl = await getAuthCodeUrl(companyId);
    // Redirect the user to the generated URL
    window.location.href = authCodeUrl;
  };

  const handleDisconnect = async (id: string) => {
    await disconnectMicrosoftSync(companyId);
    loadUserData();
  };

  // Define tab paths
  const tabPaths = ["/files", "/category-permission", "/customer-permission"];
  const currentPath = location.pathname.split("/").pop();
  const initialSelectedTab = tabPaths.indexOf(`/${currentPath}`) !== -1 ? tabPaths.indexOf(`/${currentPath}`) : 0;
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
    

  if (!userEntity) {
    return null;
  }

  const UsersGroupsNode = userEntity.usersGroups
    .map((Entity) => {
      return Entity.name;
    })
    .join(", ");

  const handleTabChange = (selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
    navigate(`/app/${companyId}/users/${userId}${tabPaths[selectedTabIndex]}`, { replace: true });
  };

  const tabs = [
    { id: "files", content: t("files") },
    { id: "category-permission", content: t("category_permission") },
    { id: "customer-permission", content: t("customer_permission") },
  ];

  return (
    <Page
      fullWidth
      backAction={{ content: "User-page", url: `/app/${companyId}/users` }}
      title={userEntity.name}
      titleMetadata={isLoading && <Spinner size="small" />}
    >
      <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} />
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            {selectedTab === 1 && (
              <FilesCategoriesTree/>
            )}
            {selectedTab === 0 && (
              <Card roundedAbove="sm">
                <div className="flex">
                  <Text as="h2" variant="headingSm">
                    {t("files")}
                  </Text>
                  <div className="flex-grow"></div>
                  <ButtonGroup>
                    <UploadFile
                      onChange={handleOnFileChange}
                      subject="user"
                      subjectId={userEntity.id}
                      categoryId={null}
                      categoryType={"internal"}
                    />
                  </ButtonGroup>
                </div>
                {userEntity.files.length > 0 ? (
                  <Files files={userEntity.files} />
                ) : (
                  <EmptyState
                    heading={t("no_files")}
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                  >
                    <UploadFile
                      onChange={handleOnFileChange}
                      subject="user"
                      subjectId={userEntity.id}
                      categoryId={null}
                      categoryType={"internal"}
                    />
                  </EmptyState>
                )}
              </Card>
            )}
            {selectedTab === 2 && (
              <Customers />
            )}
          </BlockStack>
        </Layout.Section>
        {(selectedTab === 0 || selectedTab === 1) && (
          <Layout.Section variant="oneThird">
            <BlockStack gap="500">
              <Card roundedAbove="sm">
                <InlineStack align="end">
                  <Button
                    variant="plain"
                    onClick={() => handleEditClick(userEntity.id)}
                    accessibilityLabel="Preview"
                  >
                    {t("edit")}
                  </Button>
                </InlineStack>
                <TextContainer>
                  <Text as="p" variant="bodyMd">
                    <strong>{t("Email")}:</strong> {userEntity.email}
                  </Text>
                  {userEntity.name ? (
                    <Text as="p" variant="bodyMd">
                      <strong>{t("name")}:</strong> {userEntity.name}
                    </Text>
                  ) : (
                    <Text as="p" variant="bodyMd">
                      {t("no_name_provided")}
                    </Text>
                  )}
                  {userEntity.phone ? (
                    <Text as="p" variant="bodyMd">
                      <strong>{t("phone")}:</strong> {userEntity.phone}
                    </Text>
                  ) : (
                    <Text as="p" variant="bodyMd">
                      {t("no_phone_number_provided")}
                    </Text>
                  )}
                  {userEntity.externalCode ? (
                    <Text as="p" variant="bodyMd">
                      <strong>{t("external_code")}:</strong>{" "}
                      {userEntity.externalCode}
                    </Text>
                  ) : (
                    <Text as="p" variant="bodyMd">
                      {t("no_external_code_provided")}
                    </Text>
                  )}
                  {userEntity?.manager ? (
                    <Text as="p" variant="bodyMd">
                      <strong>{t("manager")}:</strong> {userEntity?.manager?.name}
                    </Text>
                  ) : (
                    <Text as="p" variant="bodyMd">
                      {t("no_manager_provided")}
                    </Text>
                  )}
                  {userEntity.usersGroups.length > 0 ? (
                    <Text as="p" variant="bodyMd">
                      <strong>{t("user_group")}:</strong> {UsersGroupsNode}
                    </Text>
                  ) : (
                    <Text as="p" variant="bodyMd">
                      {t("no_user_group_provided")}
                    </Text>
                  )}
                </TextContainer>
              </Card>

              <Card roundedAbove="sm">
                <Text as="p" variant="bodyMd">
                  <strong>{t("email_connect")}:</strong> {userEntity.email}
                </Text>

                <Text as="p" variant="bodyMd">
                  <strong>{t("status")}:</strong>{" "}
                  {userEntity?.emailSync?.microsoft?.isConnected
                    ? t("connected")
                    : t("not_connected")}
                </Text>
                {!userEntity?.emailSync?.microsoft?.isConnected && (
                  <InlineStack align="center">
                    <Button
                      size="large"
                      onClick={() => handleAuthCodeUrl(userEntity.id)}
                    >
                      {t("connect")}
                    </Button>
                  </InlineStack>
                )}
                {userEntity?.emailSync?.microsoft?.isConnected && (
                  <InlineStack align="center">
                    <Button
                      size="large"
                      onClick={() => handleDisconnect(userEntity.id)}
                    >
                      {t("disconnect")}
                    </Button>
                  </InlineStack>
                )}
              </Card>
            </BlockStack>
          </Layout.Section>
        )}
      </Layout>
    </Page>
  );
}

export default UserContainer;
