import { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { Combobox, LegacyStack, Tag, Listbox } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RootState } from "configureStore";
import { useSelector } from "react-redux";
import { fetchAddressList } from "features/customer-address-select-multiple/customer-address-select-multiple.slice";
import { useAppDispatch } from "hooks";
import { Address } from "types/Address";


interface CustomerAddressSelectProps {
  value: string[];
  onChange: (selected: string[], name: string) => void;
  isRequired?: boolean;
  name?: string;
  isDisable?: boolean;
  label?: string;
  customerId?: string;
}


const CustomerAddressSelect: React.FC<CustomerAddressSelectProps> = ({
  value = [],
  onChange,
  isRequired = false,
  name = "",
  isDisable = false,
  label = "select_address",
  customerId = "",
}) => {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>(value);
  const dispatch = useAppDispatch();

  const handleMultiSelectChange = useCallback(
    (selected: string) => {
      if (selected) {
        const updatedSelection = selectedTags.includes(selected)
          ? selectedTags.filter((tag) => tag !== selected)
          : [...selectedTags, selected];
        setSelectedTags(updatedSelection);
        setInputValue("");
        onChange(updatedSelection, name);
      }
    },
    [selectedTags, onChange, name]
  );

  const handleRemoveTag = useCallback(
    (tag: string) => () => {
      const updatedSelection = selectedTags.filter((item) => item !== tag);
      setSelectedTags(updatedSelection);
      onChange(updatedSelection, name);
    },
    [selectedTags, onChange, name]
  );

  useEffect(() => {
    if (isRequired && !selectedTags.length) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }
  }, [selectedTags, isRequired, t]);

  const { addressesCollection } = useSelector(
    (state: RootState) => state.addressListCollectionReducer
  );

  useEffect(() => {
    dispatch(fetchAddressList(companyId!, customerId));
  }, [dispatch, companyId, customerId]);

  const collection = _.map(addressesCollection, (row:Address) => ({
    label: row.name,
    address: row.address,
    value: row.id,
    type: row.subject
  }));

  const optionsMarkup = collection.map((option, index) => {
    const isSelected = selectedTags.includes(option.value);
    return (
      <Listbox.Option
        key={index}
        value={option.value}
        selected={isSelected}
        accessibilityLabel={option.label}
      >
        <Listbox.TextOption selected={isSelected} >
          {option.label} ({option.address})
        </Listbox.TextOption>
      </Listbox.Option>
    );
  });

  return (
    <div>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            autoComplete="off"
            label={t(label)}
            value={inputValue}
            placeholder={t("search_tags")}
            onChange={setInputValue}
            disabled={isDisable}
            error={error}
            requiredIndicator={isRequired}
            verticalContent={
              <LegacyStack spacing="extraTight">
                {selectedTags.map((tag, index) => (
                  <Tag key={index} onRemove={handleRemoveTag(tag)}>
                    {/* {collection.find((item) => item.value === tag)?.label} */}
                    {collection.find((item) => item.value === tag) ?
                      `${collection.find((item) => item.value === tag)?.label} (${collection.find((item) => item.value === tag)?.address})`
                      : null}
                  </Tag>
                ))}
              </LegacyStack>
            }
          />
        }
      >
        <Listbox onSelect={(selected) => handleMultiSelectChange(selected as string)}>
          {optionsMarkup}
        </Listbox>
      </Combobox>
    </div>
  );
};

export default CustomerAddressSelect;
