import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Checkbox,
  Page,
  Form,
  Card,
  InlineGrid,
  Text,
  InlineStack,
  Select,
  Bleed,
  Box,
  BlockStack,
  FormLayout,
  Button,
  TextField,
  EmptyState,
  DropZone,
  ResourceList,
  ResourceItem,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "constants";
import "./customer-form.scss";
import Loading from "components/loading/Loading";
import { uploadWithFormKey } from "services/upload-file.service";
import { validateEmail } from "common/validator";

function CustomerForm() {
  const { formKey } = useParams();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showFormError, setShowFormError] = useState(false);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    city: "",
    country: "",
    zipCode: "",
    iban: "",
    iban2: "",
    vatCode: "",
    vatCode2: "",
    phone: "",
    email: "",
    website: "",
    bankName: "",
    swiftCode: "",
    companyCode: "",
    contactFullName: "",
    contactPhone: "",
    contactPosition: "",
    contactEmail: "",
    signatureFullName: "",
    signaturePosition: "",
    signatureEmail: "",
    signatureBasisOfRepresentation: "",
    invoiceEmail: "",
    paymentsFullName: "",
    paymentsPhone: "",
    paymentsPosition: "",
    paymentsEmail: "",
    signerPosition: "",
    signerFirstName: "",
    signerLastName: "",
    officeAddress: "",
    uploadedFiles: [],
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [differentContactPerson, setDifferentContactPerson] = useState(false);
  const [differentPaymentsPerson, setDifferentPaymentsPerson] = useState(false);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (field) => (value) => {
      setFormData({ ...formData, [field]: value });
    },
    [formData]
  );

  const toggleOpenFileDialog = useCallback(
    // eslint-disable-next-line no-shadow
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    []
  );

  const handleDropZoneDrop = useCallback(
    async (acceptedFiles) => {
      setIsFileUploadLoading(true);
      try {
        const uploadedFiles = [...formData.uploadedFiles];
        for (let i = 0; i < acceptedFiles.length; i++) {
          const { data } = await uploadWithFormKey(acceptedFiles[i], formKey);
          uploadedFiles.push(data);
        }
        handleChange("uploadedFiles")(uploadedFiles);
      } catch (e) {
        console.log(e);
      }
      setIsFileUploadLoading(false);
    },
    [formKey, formData.uploadedFiles, handleChange]
  );

  const handleFileDelete = useCallback(
    (fileId) => {
      const updatedFiles = formData.uploadedFiles.filter(
        ({ id }) => id !== fileId
      );
      handleChange("uploadedFiles")(updatedFiles);
    },
    [formData.uploadedFiles, handleChange]
  );

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = t("customer_form-name_required");
    if (!formData.companyCode)
      errors.companyCode = t("customer_form-companyCode_required");
    if (!formData.phone) errors.phone = t("customer_form-phone_required");
    if (!formData.email || !validateEmail(formData.email))
      errors.email = t("customer_form-valid_email_required");
    if (!formData.address) errors.address = t("customer_form-address_required");
    if (!formData.city) errors.city = t("customer_form-city_required");
    if (!formData.country) errors.country = t("customer_form-country_required");
    if (!formData.zipCode) errors.zipCode = t("customer_form-zipCode_required");
    if (!formData.bankName)
      errors.bankName = t("customer_form-bankName_required");
    if (!formData.iban) errors.iban = t("customer_form-iban_required");
    if (!formData.signatureFullName)
      errors.signatureFullName = t("customer_form-signature_fullName_required");
    if (!formData.signaturePosition)
      errors.signaturePosition = t("customer_form-signature_position_required");
    if (!formData.signatureEmail || !validateEmail(formData.signatureEmail))
      errors.signatureEmail = t("customer_form-signature_valid_email_required");
    if (!formData.signatureBasisOfRepresentation)
      errors.signatureBasisOfRepresentation = t(
        "customer_form-signature_basis_of_representation_required"
      );
    if (!formData.invoiceEmail || !validateEmail(formData.invoiceEmail))
      errors.invoiceEmail = t("customer_form-invoice_valid_email_required");
    if (!formData.signerPosition)
      errors.signerPosition = t("customer_form-signer_position_required");
    if (!formData.signerFirstName)
      errors.signerFirstName = t("customer_form-signer_firstName_required");
    if (!formData.signerLastName)
      errors.signerLastName = t("customer_form-signer_lastName_required");

    if (differentContactPerson) {
      if (!formData.contactFullName)
        errors.contactFullName = t("customer_form-contact_fullName_required");
      if (!formData.contactPhone)
        errors.contactPhone = t("customer_form-contact_phone_required");
      if (!formData.contactPosition)
        errors.contactPosition = t("customer_form-contact_position_required");
      if (!formData.contactEmail || !validateEmail(formData.contactEmail))
        errors.contactEmail = t("customer_form-contact_valid_email_required");
    }

    if (differentPaymentsPerson) {
      if (!formData.paymentsFullName)
        errors.paymentsFullName = t("customer_form-payments_fullName_required");
      if (!formData.paymentsPhone)
        errors.paymentsPhone = t("customer_form-payments_phone_required");
      if (!formData.paymentsPosition)
        errors.paymentsPosition = t("customer_form-payments_position_required");
      if (!formData.paymentsEmail || !validateEmail(formData.paymentsEmail))
        errors.paymentsEmail = t("customer_form-payments_valid_email_required");
    }

    if (formData.uploadedFiles.length === 0) {
      errors.documents = t("customer_form-documents_required");
    }

    return errors;
  };

  const handleSubmit = async () => {
    setFormSubmitLoading(true);
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setShowFormError(true);
      setFormSubmitLoading(false);
      return;
    }
    setShowFormError(false);

    const payload = {
      ...formData,
      address: `${formData.address}, ${formData.city}, ${formData.zipCode}, ${formData.country}`,
    };

    try {
      await axios.put(`${API_URL}/customers/form-key/${formKey}`, payload);
      // Add success handling here if needed
      setFormSubmitLoading(false);
      setIsSuccess(true);
    } catch (e) {
      setFormSubmitLoading(false);
      console.error(e);
      // Add error handling here if needed
    }
  };

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const customerEntity = await axios
          .get(`${API_URL}/customers/form-key/${formKey}`)
          .then((response) => response.data);

        if (customerEntity) {
          setFormData({
            ...customerEntity,
            uploadedFiles: [],
            country: customerEntity.country || "LT",
          });
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        console.error(e);
      }
    };

    fetchCustomerData();
  }, [formKey]);

  if (isError) {
    return (
      <EmptyState
        heading={t("customer_form-error_heading")}
        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <p>{t("customer_form-error_message")}</p>
      </EmptyState>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess) {
    return (
      <EmptyState
        heading={t("customer_form-success_heading")}
        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <p>{t("customer_form-success_text")}</p>
      </EmptyState>
    );
  }

  return (
    <Page title={`${t("customer_form")} / Customer form`}>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("customer_form-general_info")} / Customer general info
                </Text>
              </InlineGrid>
              <div className="customer-form-grid">
                <BlockStack gap="500">
                  <TextField
                    label={`${t("customer_form-name")}/Name`}
                    value={formData.name}
                    onChange={handleChange("name")}
                    requiredIndicator
                    error={validationErrors.name}
                    helpText={t("customer_form-name_help")}
                  />
                  <TextField
                    label={`${t("customer_form-companyCode")}/Company Code`}
                    value={formData.companyCode}
                    onChange={handleChange("companyCode")}
                    requiredIndicator
                    error={validationErrors.companyCode}
                    helpText={t("customer_form-companyCode_help")}
                  />
                  <TextField
                    label={`${t("customer_form-vatCode")}/VatCode`}
                    value={formData.vatCode}
                    onChange={handleChange("vatCode")}
                    helpText={t("customer_form-vatCode_help")}
                  />
                  <TextField
                    label={`${t("customer_form-vatCode2")}/VatCode2`}
                    value={formData.vatCode2}
                    onChange={handleChange("vatCode2")}
                    helpText={t("customer_form-vatCode2_help")}
                  />
                  <Bleed marginInline="400">
                    <Box
                      background="bg-surface-secondary"
                      paddingBlock="200"
                      paddingInline="400"
                    >
                      <Text as="h3" variant="headingSm">
                        {`${t("customer_form-reg_address")}/Reg address`}
                      </Text>
                      <Select
                        label={`${t("customer_form-country")}/Country`}
                        options={[
                          { value: "LT", label: t("Lithuania") },
                          { value: "AF", label: t("Afghanistan") },
                          { value: "AL", label: t("Albania") },
                          { value: "DZ", label: t("Algeria") },
                          { value: "AD", label: t("Andorra") },
                          { value: "AO", label: t("Angola") },
                          { value: "AG", label: t("Antigua and Barbuda") },
                          { value: "AR", label: t("Argentina") },
                          { value: "AM", label: t("Armenia") },
                          { value: "AU", label: t("Australia") },
                          { value: "AT", label: t("Austria") },
                          { value: "AZ", label: t("Azerbaijan") },
                          { value: "BS", label: t("Bahamas") },
                          { value: "BH", label: t("Bahrain") },
                          { value: "BD", label: t("Bangladesh") },
                          { value: "BB", label: t("Barbados") },
                          { value: "BY", label: t("Belarus") },
                          { value: "BE", label: t("Belgium") },
                          { value: "BZ", label: t("Belize") },
                          { value: "BJ", label: t("Benin") },
                          { value: "BT", label: t("Bhutan") },
                          { value: "BO", label: t("Bolivia") },
                          { value: "BA", label: t("Bosnia and Herzegovina") },
                          { value: "BW", label: t("Botswana") },
                          { value: "BR", label: t("Brazil") },
                          { value: "BN", label: t("Brunei") },
                          { value: "BG", label: t("Bulgaria") },
                          { value: "BF", label: t("Burkina Faso") },
                          { value: "BI", label: t("Burundi") },
                          { value: "KH", label: t("Cambodia") },
                          { value: "CM", label: t("Cameroon") },
                          { value: "CA", label: t("Canada") },
                          { value: "CV", label: t("Cape Verde") },
                          { value: "CF", label: t("Central African Republic") },
                          { value: "TD", label: t("Chad") },
                          { value: "CL", label: t("Chile") },
                          { value: "CN", label: t("China") },
                          { value: "CO", label: t("Colombia") },
                          { value: "KM", label: t("Comoros") },
                          { value: "CG", label: t("Congo") },
                          { value: "CD", label: t("Congo, Democratic Republic") },
                          { value: "CR", label: t("Costa Rica") },
                          { value: "HR", label: t("Croatia") },
                          { value: "CU", label: t("Cuba") },
                          { value: "CY", label: t("Cyprus") },
                          { value: "CZ", label: t("Czech Republic") },
                          { value: "DK", label: t("Denmark") },
                          { value: "DJ", label: t("Djibouti") },
                          { value: "DO", label: t("Dominican Republic") },
                          { value: "EC", label: t("Ecuador") },
                          { value: "EG", label: t("Egypt") },
                          { value: "SV", label: t("El Salvador") },
                          { value: "GQ", label: t("Equatorial Guinea") },
                          { value: "ER", label: t("Eritrea") },
                          { value: "EE", label: t("Estonia") },
                          { value: "ET", label: t("Ethiopia") },
                          { value: "FJ", label: t("Fiji") },
                          { value: "FI", label: t("Finland") },
                          { value: "FR", label: t("France") },
                          { value: "GA", label: t("Gabon") },
                          { value: "GM", label: t("Gambia") },
                          { value: "GE", label: t("Georgia") },
                          { value: "DE", label: t("Germany") },
                          { value: "GH", label: t("Ghana") },
                          { value: "GR", label: t("Greece") },
                          { value: "GT", label: t("Guatemala") },
                          { value: "GN", label: t("Guinea") },
                          { value: "GY", label: t("Guyana") },
                          { value: "HT", label: t("Haiti") },
                          { value: "HN", label: t("Honduras") },
                          { value: "HU", label: t("Hungary") },
                          { value: "IS", label: t("Iceland") },
                          { value: "IN", label: t("India") },
                          { value: "ID", label: t("Indonesia") },
                          { value: "IR", label: t("Iran") },
                          { value: "IQ", label: t("Iraq") },
                          { value: "IE", label: t("Ireland") },
                          { value: "IL", label: t("Israel") },
                          { value: "IT", label: t("Italy") },
                          { value: "JM", label: t("Jamaica") },
                          { value: "JP", label: t("Japan") },
                          { value: "JO", label: t("Jordan") },
                          { value: "KZ", label: t("Kazakhstan") },
                          { value: "KE", label: t("Kenya") },
                          { value: "KW", label: t("Kuwait") },
                          { value: "LV", label: t("Latvia") },
                          { value: "LB", label: t("Lebanon") },
                          { value: "LU", label: t("Luxembourg") },
                          { value: "MG", label: t("Madagascar") },
                          { value: "MY", label: t("Malaysia") },
                          { value: "MX", label: t("Mexico") },
                          { value: "MA", label: t("Morocco") },
                          { value: "NL", label: t("Netherlands") },
                          { value: "NZ", label: t("New Zealand") },
                          { value: "NG", label: t("Nigeria") },
                          { value: "NO", label: t("Norway") },
                          { value: "PK", label: t("Pakistan") },
                          { value: "PL", label: t("Poland") },
                          { value: "PT", label: t("Portugal") },
                          { value: "RO", label: t("Romania") },
                          { value: "RU", label: t("Russia") },
                          { value: "SA", label: t("Saudi Arabia") },
                          { value: "RS", label: t("Serbia") },
                          { value: "SG", label: t("Singapore") },
                          { value: "ZA", label: t("South Africa") },
                          { value: "ES", label: t("Spain") },
                          { value: "SE", label: t("Sweden") },
                          { value: "CH", label: t("Switzerland") },
                          { value: "TR", label: t("Turkey") },
                          { value: "UA", label: t("Ukraine") },
                          { value: "GB", label: t("United Kingdom") },
                          { value: "US", label: t("United States") },
                          { value: "VN", label: t("Vietnam") }
                        ]}
                        
                        onChange={handleChange("country")}
                        value={formData.country}
                        requiredIndicator
                        error={validationErrors.country}
                        helpText={`${t("customer_form-country_help")}`}
                      />
                      <TextField
                        label={`${t("customer_form-city")}/City`}
                        value={formData.city}
                        onChange={handleChange("city")}
                        requiredIndicator
                        error={validationErrors.city}
                        helpText={t("customer_form-city_help")}
                      />
                      <TextField
                        label={`${t("customer_form-address")}/Address`}
                        value={formData.address}
                        onChange={handleChange("address")}
                        requiredIndicator
                        error={validationErrors.address}
                        helpText={t("customer_form-address_help")}
                      />
                      <TextField
                        label={`${t("customer_form-zipCode")}/Zip Code`}
                        value={formData.zipCode}
                        onChange={handleChange("zipCode")}
                        requiredIndicator
                        error={validationErrors.zipCode}
                        helpText={t("customer_form-zipCode_help")}
                      />
                    </Box>
                  </Bleed>
                  <TextField
                    label={`${t(
                      "customer_form-office_address"
                    )}/Office address`}
                    value={formData.officeAddress}
                    onChange={handleChange("officeAddress")}
                    helpText={t("customer_form-office_address_help")}
                  />
                </BlockStack>
                <BlockStack gap="500">
                  <TextField
                    label={`${t("customer_form-phone")}/Phone`}
                    value={formData.phone}
                    onChange={handleChange("phone")}
                    requiredIndicator
                    error={validationErrors.phone}
                    helpText={t("customer_form-phone_help")}
                  />
                  <TextField
                    label={`${t("customer_form-email")}/Email`}
                    value={formData.email}
                    onChange={handleChange("email")}
                    requiredIndicator
                    error={validationErrors.email}
                    helpText={t("customer_form-email_help")}
                  />
                  <TextField
                    label={`${t("customer_form-website")}/Website`}
                    value={formData.website}
                    onChange={handleChange("website")}
                    helpText={t("customer_form-website_help")}
                  />
                  <TextField
                    label={`${t("customer_form-bankName")}/Bank Name`}
                    value={formData.bankName}
                    onChange={handleChange("bankName")}
                    requiredIndicator
                    error={validationErrors.bankName}
                    helpText={t("customer_form-bankName_help")}
                  />
                  <TextField
                    label={`${t("customer_form-iban")}/Iban`}
                    value={formData.iban}
                    onChange={handleChange("iban")}
                    requiredIndicator
                    error={validationErrors.iban}
                    helpText={t("customer_form-iban_help")}
                  />
                  <TextField
                    label={`${t("customer_form-iban2")}/Iban2`}
                    value={formData.iban2}
                    onChange={handleChange("iban2")}
                    helpText={t("customer_form-iban2_help")}
                    placeholder={t("customer_form-iban2_placeholder")}
                  />
                  <TextField
                    label={`${t("customer_form-swiftCode")}/Swift Code`}
                    value={formData.swiftCode}
                    onChange={handleChange("swiftCode")}
                    helpText={t("customer_form-swiftCode_help")}
                  />
                </BlockStack>
              </div>
            </BlockStack>
          </Card>
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <Text as="h3" variant="headingSm">
                  {`${t(
                    "customer_form-signature_details"
                  )}/Customer signature details`}
                </Text>
              </InlineGrid>
              <Bleed marginInline="400">
                <Box
                  background="bg-surface-secondary"
                  paddingBlock="200"
                  paddingInline="400"
                >
                  <div className="customer-form-grid">
                    <BlockStack gap="500">
                      <TextField
                        label={`${t(
                          "customer_form-signature_fullName"
                        )}/Full Name`}
                        value={formData.signatureFullName}
                        onChange={handleChange("signatureFullName")}
                        requiredIndicator
                        error={validationErrors.signatureFullName}
                        helpText={t("customer_form-signature_fullName_help")}
                      />
                      <TextField
                        label={`${t(
                          "customer_form-signature_position"
                        )}/Position`}
                        value={formData.signaturePosition}
                        onChange={handleChange("signaturePosition")}
                        requiredIndicator
                        error={validationErrors.signaturePosition}
                        helpText={t("customer_form-signature_position_help")}
                      />
                    </BlockStack>
                    <BlockStack gap="500">
                      <TextField
                        label={`${t("customer_form-signature_email")}/Email`}
                        type="email"
                        value={formData.signatureEmail}
                        onChange={handleChange("signatureEmail")}
                        requiredIndicator
                        error={validationErrors.signatureEmail}
                        helpText={t("customer_form-signature_email_help")}
                      />
                      <TextField
                        label={`${t(
                          "customer_form-signature_basis_of_representation"
                        )}/Basis of representation`}
                        value={formData.signatureBasisOfRepresentation}
                        onChange={handleChange(
                          "signatureBasisOfRepresentation"
                        )}
                        requiredIndicator
                        error={validationErrors.signatureBasisOfRepresentation}
                        helpText={`${t(
                          "customer_form-signature_basis_of_representation_help"
                        )}`}
                      />
                    </BlockStack>
                  </div>
                </Box>
              </Bleed>
              <br />
              <Checkbox
                label={`${t(
                  "customer_form-different_contact_person"
                )}/Customer different contact person`}
                checked={differentContactPerson}
                onChange={setDifferentContactPerson}
              />
              {differentContactPerson && (
                <>
                  <Text as="h2" variant="headingSm">
                    {`${t("customer_form-contacts")}/Customer contacts`}
                  </Text>
                  <div className="customer-form-grid">
                    <BlockStack gap="500">
                      <TextField
                        label={`${t(
                          "customer_form-contact_fullName"
                        )}/Full Name`}
                        value={formData.contactFullName}
                        onChange={handleChange("contactFullName")}
                        requiredIndicator
                        error={validationErrors.contactFullName}
                        helpText={t("customer_form-contact_fullName_help")}
                      />
                      <TextField
                        label={`${t("customer_form-contact_phone")}/Phone`}
                        value={formData.contactPhone}
                        onChange={handleChange("contactPhone")}
                        requiredIndicator
                        error={validationErrors.contactPhone}
                        helpText={t("customer_form-contact_phone_help")}
                      />
                    </BlockStack>
                    <BlockStack gap="500">
                      <TextField
                        label={`${t(
                          "customer_form-contact_position"
                        )}/Position`}
                        value={formData.contactPosition}
                        onChange={handleChange("contactPosition")}
                        requiredIndicator
                        error={validationErrors.contactPosition}
                        helpText={t("customer_form-contact_position_help")}
                      />
                      <TextField
                        label={`${t("customer_form-contact_email")}/Email`}
                        type="email"
                        value={formData.contactEmail}
                        onChange={handleChange("contactEmail")}
                        requiredIndicator
                        error={validationErrors.contactEmail}
                        helpText={t("customer_form-contact_email_help")}
                      />
                    </BlockStack>
                  </div>
                </>
              )}
            </BlockStack>
          </Card>
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {`${t("customer_form-other_details")}/Customer other details`}
                </Text>
              </InlineGrid>
              <div className="customer-form-grid">
                <BlockStack gap="500">
                  <TextField
                    label={`${t(
                      "customer_form-invoice_email"
                    )}/Customer invoice email`}
                    value={formData.invoiceEmail}
                    onChange={handleChange("invoiceEmail")}
                    helpText={t("customer_form-invoice_email_help")}
                    requiredIndicator
                    error={validationErrors.invoiceEmail}
                  />
                </BlockStack>
                <BlockStack gap="500"></BlockStack>
              </div>
            </BlockStack>
          </Card>
          <Card roundedAbove="sm">
            <Checkbox
              label={`${t(
                "customer_form-different_payments_person"
              )}/Customer different contact person`}
              checked={differentPaymentsPerson}
              onChange={setDifferentPaymentsPerson}
            />
            {differentPaymentsPerson && (
              <BlockStack gap="200">
                <InlineGrid columns="1fr auto">
                  <Text as="h2" variant="headingSm">
                    {`${t("customer_form-payments")}/Customer payments`}
                  </Text>
                </InlineGrid>
                <div className="customer-form-grid">
                  <BlockStack gap="500">
                    <TextField
                      label={`${t(
                        "customer_form-payments_fullName"
                      )}/Full Name`}
                      value={formData.paymentsFullName}
                      onChange={handleChange("paymentsFullName")}
                      requiredIndicator
                      error={validationErrors.paymentsFullName}
                      helpText={t("customer_form-payments_fullName_help")}
                    />
                    <TextField
                      label={`${t("customer_form-payments_position")}/Position`}
                      value={formData.paymentsPosition}
                      onChange={handleChange("paymentsPosition")}
                      requiredIndicator
                      error={validationErrors.paymentsPosition}
                      helpText={t("customer_form-payments_position_help")}
                    />
                  </BlockStack>
                  <BlockStack gap="500">
                    <TextField
                      label={`${t("customer_form-payments_phone")}/Phone`}
                      value={formData.paymentsPhone}
                      onChange={handleChange("paymentsPhone")}
                      requiredIndicator
                      error={validationErrors.paymentsPhone}
                      helpText={t("customer_form-payments_phone_help")}
                    />
                    <TextField
                      label={`${t("customer_form-payments_email")}/Email`}
                      type="email"
                      value={formData.paymentsEmail}
                      onChange={handleChange("paymentsEmail")}
                      requiredIndicator
                      error={validationErrors.paymentsEmail}
                      helpText={t("customer_form-payments_email_help")}
                    />
                  </BlockStack>
                </div>
              </BlockStack>
            )}
          </Card>
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {`${t("customer_form-documents")}/Documents`}
                </Text>
                <Button
                  loading={isFileUploadLoading}
                  onClick={toggleOpenFileDialog}
                > 
                  {`${t("upload")}/Upload`}
                </Button>
                <div style={{ display: "none" }}>
                  <DropZone
                    allowMultiple={true}
                    openFileDialog={openFileDialog}
                    onDropAccepted={handleDropZoneDrop}
                    onFileDialogClose={toggleOpenFileDialog}
                  >
                    <DropZone.FileUpload />
                  </DropZone>
                </div>
              </InlineGrid>
              <Text as="p" tone={validationErrors.documents ? "critical" : ""}>
                Reikalingi pateikti dokumentai / Required documents to be
                submitted:
                <ul>
                  <li>
                    Įmonės registracijos dokumentų išrašo kopija / A copy of the
                    company registration document extract
                  </li>
                  <li>
                    Paskutinės finansinės ataskaitos kopija / A copy of the
                    latest financial statement
                  </li>
                  <li>
                    Nurodytos banko sąskaitos patvirtinimo įrodymas / Proof of
                    the specified bank account confirmation
                  </li>
                  <li>
                    Įmonės įgaliojimas, jeigu veikiama bus su įgaliojimu / A
                    company authorization, if acting under a power of attorney
                  </li>
                </ul>
              </Text>
              <ResourceList
                resourceName={{
                  singular: t("customer_form-document"),
                  plural: t("customer_form-documents"),
                }}
                items={formData.uploadedFiles}
                renderItem={(item) => {
                  const { id, filename } = item;

                  return (
                    <ResourceItem id={id}>
                      <InlineGrid columns="1fr auto">
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {filename}
                        </Text>
                        <Button
                          onClick={() => {
                            if (
                              window.confirm(
                                t("customer_form-delete_confirmation")
                              )
                            ) {
                              handleFileDelete(id);
                            }
                          }}
                          destructive
                        >
                          {t("customer_form-delete")}
                        </Button>
                      </InlineGrid>
                    </ResourceItem>
                  );
                }}
              />
            </BlockStack>
          </Card>
          <Card roundedAbove="sm" className="customer_form-signature_block">
            <BlockStack gap="200">
              <BlockStack gap="200">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "16px",
                  }}
                >
                  {/* Row 1 */}
                  <div>
                    <strong>Patvirtinu,</strong> kad man, mano vadovaujamam
                    (-ai) (atstovaujamam (-ai)) Bendrovei ir tiesiogiai ar
                    netiesiogiai jį/ją valdančiam (-tiems) (kontroliuojančiam
                    (-tiems)), kuriam (-iems) asmeniui (-ims), taip pat man
                    žinomiems bendrovės tiekėjams, subtiekėjams, subrangovams
                    ir/ar kitiems tretiesiems asmenims, jeigu tokie yra
                    pasitelkiami sutarties vykdymui, ir/ar jų vykdomos
                    veiklos/tiekiamų produktų atžvilgiu, Sutarties sudarymo
                    momentu nėra taikomos tarptautinės (tame tarpe ir Jungtinių
                    Amerikos Valstijų) sankcijos ar kitos Europos Sąjungos
                    (toliau – ES) nustatytos ribojamosios priemonės.
                  </div>
                  <div>
                    <strong>I confirm</strong> that neither I, nor the company I
                    manage (represent), nor the person(s) directly or indirectly
                    controlling it, nor its suppliers, subcontractors, and/or
                    other third parties engaged in contract execution, nor the
                    products or activities of these entities are subject to
                    international sanctions (including those of the United
                    States) or other restrictive measures set by the European
                    Union (hereinafter – EU) at the moment of signing the
                    Agreement.
                  </div>

                  {/* Row 2 */}
                  <div>
                    <strong>Man žinoma,</strong> kad jeigu ši deklaracija yra
                    neteisinga, melaginga ir (ar) klaidinanti, su mano
                    vadovaujama (atstovaujama) bendrove sudaryta Sutartis gali
                    būti nutraukta ir mano vadovaujama (atstovaujama) bendrovė
                    privalės atlyginti dėl šios melagingos deklaracijos
                    pateikimo patirtus nukentėjusios šalies nuostolius.
                  </div>
                  <div>
                    <strong>I am aware</strong> that if this declaration is
                    false, misleading, or incorrect, the Agreement concluded
                    with the company I manage (represent) may be terminated, and
                    the company I manage (represent) will be liable for damages
                    incurred by the affected party as a result of this false
                    declaration.
                  </div>

                  {/* Row 3 */}
                  <div>
                    <strong>Paaiškėjus</strong> apie man, mano vadovaujamam
                    (atstovaujamam) asmeniui, tiesiogiai ar netiesiogiai jį
                    valdančiam (-tiems) (kontroliuojančiam (-tiems)) asmeniui
                    (-ims), taip pat bendrovės tiekėjams, subtiekėjams,
                    subrangovams ir/ar kitiems tretiesiems asmenims, jeigu tokie
                    yra pasitelkiami sutarties vykdymui, ir/ar jų vykdomos
                    veiklos/tiekiamų produktų atžvilgiu, taikomas tarptautines
                    (tame tarpe ir Jungtinių Amerikos Valstijų) sankcijas ar
                    kitas ES nustatytas ribojamąsias priemones, įsipareigoju
                    nedelsiant apie tai Jus informuoti.
                  </div>
                  <div>
                    <strong>If it becomes known</strong> that international
                    sanctions (including those of the United States) or other
                    restrictive measures imposed by the EU apply to me, the
                    company I manage (represent), its suppliers, subcontractors,
                    and/or third parties involved in contract execution, or to
                    their products or activities, I undertake to inform you
                    immediately.
                  </div>

                  {/* Row 4 */}
                  <div>
                    <strong>
                      Sutinku, kad sutartys, sąskaitos ir jų priedai būtų
                      siunčiamos tik elektroniniu būdu, šioje Anketoje
                      nurodytais elektroninio pašto adresais.
                    </strong>
                    <br />
                    Jeigu nesutinkate su sutarčių, sąskaitų ir jų priedų gavimu
                    elektroninėje formoje - Pardavėjas pasilieka teisę
                    apmokestinti pašto išlaidas.
                  </div>
                  <div>
                    <strong>I agree</strong> that contracts, invoices, and their
                    annexes will be sent exclusively in electronic form to the
                    email addresses provided in this Form.
                    <br />
                    If you do not agree to receive contracts, invoices, and
                    their annexes in electronic form, the Seller reserves the
                    right to charge postal costs.
                  </div>
                </div>
              </BlockStack>

              <Bleed marginInline="400">
                <Box
                  background="bg-surface-secondary"
                  paddingBlock="300"
                  paddingInline="400"
                >
                  <InlineStack align="space-between">
                    <TextField
                      label={`${t("customer_form-signer-position")}/Position`}
                      value={formData.signerPosition}
                      onChange={handleChange("signerPosition")}
                      autoComplete="off"
                      requiredIndicator
                      error={validationErrors.signerPosition}
                    />

                    <TextField
                      label={`${t("customer_form-signer-firstName")}/FirstName`}
                      value={formData.signerFirstName}
                      onChange={handleChange("signerFirstName")}
                      autoComplete="off"
                      requiredIndicator
                      error={validationErrors.signerFirstName}
                    />

                    <TextField
                      label={`${t("customer_form-signer-lastName")}/LastName`}
                      value={formData.signerLastName}
                      onChange={handleChange("signerLastName")}
                      autoComplete="off"
                      requiredIndicator
                      error={validationErrors.signerLastName}
                    />
                    <Button
                      variant="primary"
                      submit
                      loading={formSubmitLoading}
                    >
                      {`${t("customer_form-sign")} / Submit`}
                    </Button>
                  </InlineStack>
                  {showFormError && (
                    <InlineStack align="end" gap="200">
                      <Text tone="critical">
                        {`${t("customer_form-submit_error")} / Sign and submit the form`}
                      </Text>
                    </InlineStack>
                  )}
                </Box>
              </Bleed>
            </BlockStack>
          </Card>
        </FormLayout>
      </Form>
    </Page>
  );
}

export default CustomerForm;
