import { useState, useEffect } from "react";
import {
  LegacyCard,
  ResourceList,
  ResourceItem,
  Avatar,
  Text,
} from "@shopify/polaris";
import { useDispatch } from "react-redux";
import { AppDispatch } from "configureStore";
import { Button, InlineStack, Banner } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { setDestroy, setIsModalxOpen } from "components/modalx/modalx.slice";
import { uploadToDokobit } from "services/upload-file.service";
import { useParams } from "react-router-dom";
import { fetchFileDetails } from "containers/file/file.Slice";
import { fetchContacts } from "services/customers.service";
import { fetch } from "services/user-select.service";
import { fetchCustomer } from "containers/customer/customer-container.slice";

interface ConfirmModalProps {
  fileId: string;
  contactSigner: string;
  companyUserSigner: string;
  customerId: string;
  nontechuser: string;
  signType: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  fileId,
  contactSigner,
  companyUserSigner,
  customerId,
  nontechuser,
  signType,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedCustomerContact, setSelectedCustomerContact] =
    useState<any>("");
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [selectedNontechUser, setSelectedNontechUser] = useState<any>("");

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data = await fetchContacts(companyId, customerId);
        const matchedContact = data.contactsCollection.find(
          (contact: any) => contact.id === contactSigner
        );

        if (matchedContact)
          setSelectedCustomerContact({
            ...matchedContact,
            type: t("customer_contact"),
          });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId, customerId]);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data = await fetch(companyId, {}, false);
        const matchedUser = data.usersCollection.find(
          (user: any) => user.id === companyUserSigner
        );
        const matchedUser_ = data.usersCollection.find(
          (user: any) => user.id === nontechuser
        );
        if (matchedUser)
          setSelectedUser({ ...matchedUser, type: t("sign_permission_user") });
        if (matchedUser_)
          setSelectedNontechUser({
            ...matchedUser_,
            type: t("contact_user"),
          });

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId]);

  const handleToSendRequest = async () => {
    try {
      setIsLoading(true);
      const payload = {
        fileId,
        contactSigner,
        companyUserSigner,
        nontechuser,
        signType,
      };
      await uploadToDokobit(companyId, payload);
      if (fileId && companyId) {
        dispatch(fetchFileDetails({ fileId, companyId }));
      }
      dispatch(fetchCustomer({ customerId, companyId: companyId! }));
      setSuccessMessage("ok");
      setIsLoading(false);
      closeModal();
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    dispatch(setIsModalxOpen(false));
    dispatch(setDestroy());
  };

  return (
    <>
      <div className="form-warnings">
        {errMsg && (
          <div>
            <Banner tone="critical">{t(errMsg)}</Banner>
          </div>
        )}
        {successMessage && (
          <div>
            <Banner tone="info">{t(successMessage)}</Banner>
          </div>
        )}
      </div>
      <h1 style={{ textAlign: "center" }}>
        {t("Are_you_sure_you_want_to_send_document_signature_request")}
      </h1>

      <div style={{ margin: "10px 0" }}>
        <LegacyCard>
          <ResourceList
            resourceName={{ singular: "contact", plural: "contacts" }}
            items={[
              selectedCustomerContact && {
                id: selectedCustomerContact.id,
                name: selectedCustomerContact.name,
                email: selectedCustomerContact.email,
                type: selectedCustomerContact.type,
              },
              selectedUser && {
                id: selectedUser.id,
                name: selectedUser.name,
                email: selectedUser.email,
                type: selectedUser.type,
              },
              selectedNontechUser && {
                id: selectedNontechUser.id,
                name: selectedNontechUser.name,
                email: selectedNontechUser.email,
                type: selectedNontechUser.type,
              },
            ].filter(Boolean)}
            renderItem={(item) => (
              <ResourceItem
                id={item.id}
                media={<Avatar customer name={item.name} />}
                accessibilityLabel={`View details for ${item.name}`}
                onClick={() => ""}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Text as="h3" variant="headingMd">
                      {item.name}
                    </Text>
                    <Text as="h3">{item.email}</Text>
                  </div>
                  <div>
                    <Text as="h3" variant="bodySm">
                      {item.type}
                    </Text>
                  </div>
                </div>
              </ResourceItem>
            )}
          />
        </LegacyCard>
      </div>

      <InlineStack align="space-between">
        <Button onClick={handleToSendRequest} loading={isLoading}>
          {t("Yes")}
        </Button>

        <Button onClick={closeModal}>{t("No")}</Button>
      </InlineStack>
    </>
  );
};

export default ConfirmModal;
