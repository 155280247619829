import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "configureStore";
import { Button, InlineStack, Banner, Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import { setDestroy, setIsModalxOpen } from "components/modalx/modalx.slice";
import CustomerContactSelect from "features/customer-contact-select/customer-contact-select";
import UserSelectByPermissions from "features/user-select-by-permission/user-select-by-permission";
import { addSigner } from "services/dokobit.service";
import { fetchFileDetails } from "containers/file/file.Slice";
import UserSelect from "features/user-select/user-select";

interface ConfirmModalProps {
  companyId: string;
  customerId: string;
  fileId?: string;
  relatedTo?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  companyId,
  customerId,
  fileId,
  relatedTo,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { customer } = useSelector((state: any) => state.customerReducer);
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(
    customer?.assignedUser?.id || ""
  );
  const [contactSigner, setContactSigner] = useState("");
  const [signType, setSignType] = useState("Dokobit");
  const [selectedNonTechUser, setSelectedNonTechUser] = useState(
    customer?.assignedUser?.id || ""
  );

  const handleDokobitFile = async () => {
    try {
      setIsLoading(true);
      const payload = {
        fileId,
        contactSigner: contactSigner,
        companyUserSigner: selectedUser,
        signType,
        nontechuser: selectedNonTechUser,
      };
      await addSigner(companyId, payload);
      if (relatedTo) {
        dispatch(fetchFileDetails({ fileId: relatedTo, companyId }));
      } else if (fileId && companyId) {
        dispatch(fetchFileDetails({ fileId, companyId }));
      }

      dispatch(fetchCustomer({ customerId, companyId: companyId! }));
      setSuccessMessage("ok");
      setIsLoading(false);
      closeModal();
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    dispatch(setIsModalxOpen(false));
    dispatch(setDestroy());
  };

  const signCollection = [
    { label: t("manual_sign"), value: "Manual" },
    { label: "Dokobit", value: "Dokobit" },
  ];

  const handleSignType = (value: string) => {
    setSignType(value);
  };

  return (
    <>
      <div className="form-warnings">
        {errMsg && (
          <div>
            <Banner tone="critical">{t(errMsg)}</Banner>
          </div>
        )}
        {successMessage && (
          <div>
            <Banner tone="info">{t(successMessage)}</Banner>
          </div>
        )}
        {
          selectedUser == selectedNonTechUser && (
          <div>
            <Banner tone="critical">{t("contact_user_and_permission_user_can_not_be_same")}</Banner>
          </div>)
        }
      </div>
      <h1 style={{ textAlign: "center" }}>
        {t("Are_you_sure_you_want_to_request_to_sign_file")}
      </h1>
      <div style={{ marginBottom: "10px" }}>
        <CustomerContactSelect
          label={"select_contact"}
          onChange={setContactSigner}
          value={contactSigner}
          customerId={customerId}
          isRequired={true}
        />

        <UserSelectByPermissions
          value={selectedUser}
          onChange={setSelectedUser}
          isRequired={true}
          Permission={"Signature"}
        />

        <UserSelect
          onChange={setSelectedNonTechUser}
          value={selectedNonTechUser}
          label={"select_user"}
        />
        <Select
          label={t("sign_type")}
          options={signCollection}
          value={signType}
          onChange={handleSignType}
          requiredIndicator={true}
        />
      </div>
      <InlineStack align="space-between">
        <Button
          onClick={handleDokobitFile}
          loading={isLoading}
          disabled={!selectedUser || !contactSigner || (selectedUser == selectedNonTechUser)}
        >
          {t("Yes")}
        </Button>

        <Button onClick={closeModal}>{t("No")}</Button>
      </InlineStack>
    </>
  );
};

export default ConfirmModal;
