import { useState } from "react";
import { useAppDispatch } from "hooks";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Select,
  Bleed,
  Box,
  InlineStack
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  createAddress,
} from "services/address.service";
import Loading from "components/loading/Loading";
import { fetchAddressList } from "features/customer-address-select-multiple/customer-address-select-multiple.slice";

function AddressForm() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { companyId, customerId } = useParams();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [subject, setSubject] = useState("company")
  const [subjectId, setSubjectId] = useState(companyId);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false); // Toggle Form State

  const handleAddressType = (value: string) => {
    setSubject(value);
    if (value === "customer") {
      setSubjectId(customerId!)
    }
    if (value === "company") {
      setSubjectId(companyId!)
    }
  };

  const addressTypeCollection = [
    { label: t("customer"), value: "customer" },
    { label: t("company"), value: "company" },
  ];

  if (!companyId) return null;

  const formResetHandler = () => {
    setName("")
    setAddress("")
    setShowForm(false)
  }

  const handleFormSubmit = async () => {
    const formData = {
      name,
      address,
      subject,
      subjectId
    };
    const action = createAddress;
    try {
      setIsLoading(true);
      await action(formData, companyId);
      if(customerId){
        dispatch(fetchAddressList(companyId, customerId));
      }
      setIsLoading(false);
      formResetHandler()
    } catch (e) {
      console.error(e);
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  if (isLoading && !name) {
    return <Loading />;
  }


  return (
    <Bleed marginInline="400">
      <Box
        background="bg-surface-brand"
        paddingBlock="200"
        paddingInline="400"
      >
        {!showForm ? (
          <Button onClick={() => setShowForm(true)}>{t("address_add")}</Button>
        ) : (

          <Form onSubmit={handleFormSubmit}>
            <FormLayout>
              <div className="form-warnings">
                {errMsg && (
                  <div className="">
                    <Banner tone="critical">{t(errMsg)}</Banner>
                  </div>
                )}
              </div>
              <TextField
                label={t(`address_name`)}
                id="name"
                name="name"
                value={name}
                onChange={setName}
                autoComplete="off"
                requiredIndicator={true}
              />
              <TextField
                label={t(`address`)}
                id="address"
                name="address"
                value={address}
                onChange={setAddress}
                autoComplete="off"
                requiredIndicator={true}
              />

              <Select
                label={t("address_type")}
                options={addressTypeCollection}
                value={subject}
                onChange={handleAddressType}
                requiredIndicator={true}
              />

              <InlineStack align="space-between">
                <Button
                  onClick={handleFormSubmit}
                  disabled={!name || !address || !subject}
                  loading={isLoading}
                >
                  {t("address_add")}
                </Button>

                <Button
                  disabled={isLoading}
                  onClick={() => setShowForm(false)}
                >
                  {t("cancel")}
                </Button>

              </InlineStack>
            </FormLayout>
          </Form>
        )}
      </Box>
    </Bleed>
  );
}

export default AddressForm;
