import React, {
    useState,
    useEffect,
    useRef,
    CSSProperties,
    ReactElement,
    useCallback,
} from "react";
import { Tree, NodeRendererProps } from "react-arborist";
import {
    Text,
    Card,
    BlockStack,
    InlineStack,
    Checkbox
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
    fetchCategories,
    setDestroy,
} from "containers/document/category-permissions-tree/category-tree-permission.Slice";
import { RootState, AppDispatch } from "configureStore";
import "../categoryTree.css";
import { Category, CategoryDataTbl } from "types/Document";
import {
    setModalxComponent,
    setModalxComponentProps,
} from "components/modalx/modalx.slice";
import useResizeObserver from "use-resize-observer";
import Loading from "components/loading/Loading";
import { assignFileCategoryPermissionToUser } from "services/files-category.service";

const FIlesCategoryTree = () => {
    const { t } = useTranslation();
    const [activeNodeId, setActiveNodeId] = useState<string>("");
    const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>({});
    const { categoriesData, isLoading } = useSelector(
        (state: RootState) => state.documentCategoryPermissionReducer
    );
    const { isUserMaster } = useSelector((state: RootState) => state.companySelectReducer);

    const { ref, height } = useResizeObserver();

    const dispatch: AppDispatch = useDispatch();
    const { companyId, userId } = useParams<{ companyId: string, userId: string }>();

    const loadFilesCategories = useCallback(() => {
        if (companyId && userId) {
            dispatch(fetchCategories({ companyId, userId }));
        }
    }, [companyId, dispatch]);

    useEffect(() => {
        loadFilesCategories();
        setActiveNodeId("");

        return () => {
            dispatch(setDestroy());
        };
    }, [companyId, loadFilesCategories]);

    function transformData(data: Category[]): CategoryDataTbl[] {
        return data?.map((item) => ({
            id: item.id,
            name: item.title,
            count: item.fileCount,
            parentId: item.parentCategory,
            children: item.subcategories ? transformData(item.subcategories) : [],
            order: item.order,
            isPermission: item.isPermission,
            allChildCheck: item.allChildCheck,
            childCategoryCount: item.childCategoryCount,
        })).sort((a, b) => (Number(a.order) ?? 0) - (Number(b.order) ?? 0));
    }

    const transformedData: CategoryDataTbl[] = transformData(categoriesData);

    const handleClick = (node: any) => {
        setActiveNodeId(node.id);
        toggleNode(node.id);
    };

    // Toggle the expanded state of a node
    const toggleNode = (nodeId: string) => {
        setExpandedNodes((prev) => ({
            ...prev,
            [nodeId]: !prev[nodeId],
        }));
    };

    const handleToggle = () => {
        // console.log(node, "node");
    };

    const nodeRef = useRef<HTMLDivElement>(null);


    const handlePermissionCategory = async(id: string, selectallchild:any) => {
        const payload = {
            user: userId, 
            category: id,
            selectallchild
        }
        await assignFileCategoryPermissionToUser(companyId, payload);
        if(companyId && userId){
            dispatch(fetchCategories({ companyId, userId }));
        }
    };

    const renderNode = ({
        node,
        style,
        dragHandle,
    }: NodeRendererProps<CategoryDataTbl>): ReactElement => {
        const isActive = node.id === activeNodeId;
        const isExpanded = expandedNodes[node.id] || false; // Check if node is expanded from localStorage

        // Ensure node is opened if it should be expanded
        if (isExpanded && !node.isOpen) {
            node.toggle(); // Ensure node is opened
        }

        return (
            <div
                style={style as CSSProperties}
                ref={nodeRef}
                tabIndex={0}
                onClick={() => handleClick(node)}
                className={isActive ? "node_txt active-node" : "node_txt"}
            >
                <InlineStack gap="100">
                    <span style={{ display: "inline-flex", alignItems: "center" }}>
                        {node.children!.length! > 0 ? (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleNode(node.id);
                                    node.toggle();
                                }}
                                style={{ marginRight: "8px" }}
                            >
                                {node.isOpen ? "-" : "+"}
                            </button>
                        ) : (
                            <button
                                disabled
                                className="disabled_btn"
                                style={{ marginRight: "8px" }}
                            ></button>
                        )}
                        <span
                            style={{
                                display: node.data.name.length ? "inline-block" : "none",
                                whiteSpace: "nowrap",
                            }}
                            title={node.data.name}
                        >
                            {node.data.name}
                        </span>
                        &nbsp;[{node.data.count <= 0 ? "x" : node.data.count}]
                        <>
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePermissionCategory(node.id, "");
                                }}
                                style={{ marginLeft: "8px" }}
                            >
                                <Checkbox
                                    label="checkbox"
                                    labelHidden
                                    checked={node.data.isPermission}
                                />
                            </span>
                            {(node.data?.childCategoryCount || 0 > 0) &&
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlePermissionCategory(node.id, node.data.allChildCheck);
                                    }}
                                    style={{ marginLeft: "8px" }}
                                >
                                    <Checkbox
                                        label="checkbox"
                                        labelHidden
                                        checked={node.data.allChildCheck}
                                    />
                                </span>  
                            }
                        </>
                    </span>
                </InlineStack>
            </div>
        );
    };

    return (
        <BlockStack gap="500">
            <Card roundedAbove="sm">
                <div className="arborist-container" ref={ref}>
                    <div className="spinner_cus_main">
                        <Text as="h2" variant="headingSm">
                            {t("category")}  
                        </Text>
                        
                       {isLoading && <span className="spinner_cus"><Loading size={"small"}/></span>}
                    </div>
                    <Tree
                        data={transformedData}
                        onToggle={handleToggle}
                        disableMultiSelection
                        idAccessor="id"
                        openByDefault={false}
                        rowHeight={30}
                        height={height}
                    // width={width}
                    >
                        {renderNode}
                    </Tree>
                </div>
            </Card>
        </BlockStack>
    );
};

export default FIlesCategoryTree;
