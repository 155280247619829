import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "configureStore";
import { Button, InlineStack, Banner, Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { setDestroy, setIsModalxOpen } from "components/modalx/modalx.slice";
import CustomerContactSelect from "features/customer-contact-select/customer-contact-select";
import UserSelectByPermissions from "features/user-select-by-permission/user-select-by-permission";
import UserSelect from "features/user-select/user-select";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import { is } from "date-fns/locale";

interface ConfirmModalProps {
  customerId: string;
  fileId?: string;
  template?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  customerId,
  fileId,
  template,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { customer } = useSelector((state: any) => state.customerReducer);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [contactSigner, setContactSigner] = useState("");
  const [signType, setSignType] = useState("Dokobit");
  const [selectedNonTechUser, setSelectedNonTechUser] = useState<any>(
    customer?.assignedUser?.id || ""
  );

  const handleOnClick = () => {
    const payload = {
      fileId,
      customerId,
      isCustomerApproved: customer?.isApproved,
      signersInfo: {
        contactSigner: contactSigner,
        companyUserSigner: selectedUser,
        signType,
        nontechuser: selectedNonTechUser,
      },
      template,
    };
    dispatch(setModalxComponent("BuildPdfForm"));
    dispatch(setModalxComponentProps(payload));
  };

  const closeModal = () => {
    dispatch(setIsModalxOpen(false));
    dispatch(setDestroy());
  };

  const signCollection = [
    { label: t("manual_sign"), value: "Manual" },
    { label: "Dokobit", value: "Dokobit" },
  ];

  const handleSignType = (value: string) => {
    setSignType(value);
  };

  return (
    <>
      <h1 style={{ textAlign: "center" }}>{t("select_signers")}</h1>
      <div style={{ marginBottom: "10px" }}>
        <CustomerContactSelect
          label={"select_contact"}
          onChange={setContactSigner}
          value={contactSigner}
          customerId={customerId}
          isRequired={true}
        />

        <UserSelectByPermissions
          value={selectedUser}
          onChange={setSelectedUser}
          isRequired={true}
          Permission={"Signature"}
        />

        <UserSelect
          onChange={setSelectedNonTechUser}
          value={selectedNonTechUser}
          label={"select_contact_user"}
        />
        <Select
          label={t("sign_type")}
          options={signCollection}
          value={signType}
          onChange={handleSignType}
          requiredIndicator={true}
        />
      </div>
      <InlineStack align="space-between">
        <Button
          onClick={handleOnClick}
          loading={isLoading}
          disabled={!selectedUser || !contactSigner}
        >
          {t("Yes")}
        </Button>

        <Button onClick={closeModal}>{t("No")}</Button>
      </InlineStack>
    </>
  );
};

export default ConfirmModal;
