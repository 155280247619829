import React, { Fragment } from "react";
import { Link, DataTable as ShopifyDataTable, Button, Checkbox } from "@shopify/polaris";
import Currency from "components/format/currency/currency";
import { useTranslation } from "react-i18next";
import PrettyDate from "components/format/PrettyDate";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { Column } from "types/Datatable";
import { hasPdfExtension } from "utils/helperFunctions";

type Props = {
  collection: any[];
  columns: Column[];
  isTotalsDisabled?: boolean;
};

const DataTable: React.FC<Props> = ({
  collection,
  columns,
  isTotalsDisabled = false,
}: Props) => {
  const { t } = useTranslation();

  const totals: any[] = columns.map((column: Column) => {
    if (column.type === "currency" || column.type === "number") {
      return 0;
    }
    return "";
  });

  const renderClickableColumn = (column: Column, item: any, value: any) => {
    const isPdf = hasPdfExtension(item.title);
    const isIconVisible = item.isVisible;
    return column.type !== "specificAction" ? (
      <div
        style={{
          cursor: "pointer",
          // height: "35px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => (isIconVisible ? column.onClick?.(item) : "")}
      >
        {column.type === "clickabledate" ? (
          value ? (
            <PrettyDate
              dateString={value || ""}
              showTime={!!column.isShowTime}
            />
          ) : (
            <span>-</span>
          )
        ) : column.type === "downloadurl" || column.type === "actionBtn" ? (
          <Button
            disabled={!isIconVisible}
            target={column.type === "downloadurl" ? "_blank" : undefined}
            icon={column.Icon}
          ></Button>
        ) : (
          <>{value || "-"}</>
        )}
      </div>
    ) : (
      <div
        style={{
          // height: "35px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => (isPdf && isIconVisible ? column.onClick?.(item) : "")}
      >
        <Button
          icon={column.Icon}
          disabled={isPdf && isIconVisible ? false : true}
        ></Button>
      </div>
    );
  };

  const renderColumnValue = (
    column: Column,
    item: any
  ): JSX.Element | string => {
    let value = _.get(item, column.key, "-");
    value = column.translationRequired ? t(value) : value;

    switch (column.type) {
      case "currency":
        return <Currency value={value} isSymbol={true} isDecimal={true} />;
      case "date":
        return <PrettyDate dateString={value} />;
      case "downloadurl":
      case "actionBtn":
      case "clickableColumn":
      case "clickabledate":
      case "specificAction":
        return renderClickableColumn(column, item, value);
      case "checkbox":
        return (
          <Checkbox
            label="access"
            labelHidden
            checked={value || false}
            onChange={() => column.onClick?.(item)}
          />
        )
      default:
        if (column.url) {
          return (
            <Link
              removeUnderline
              url={_.get(item, column.url)}
              key={column.url}
            >
              {value}
            </Link>
          );
        } else if (column.onClick) {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => column.onClick?.(item)}
            >
              <strong>{value}</strong>
            </div>
          );
        }
        const suffixValue = column.suffix ? _.get(item, column.suffix) : "";
        return `${value}${suffixValue ? ` ${suffixValue}` : ""}`;
    }
  };

  const tableData = collection.map((item: any, index: number) =>
    columns.map((column: Column, columnIndex: number) => {
      let value = _.get(item, column.key, "-");
      value = column.translationRequired ? t(value) : value;

      if (
        column.isDisabledTotal !== true &&
        (column.type === "currency" || column.type === "number")
      ) {
        if (!column.totalType || column.totalType === "sum") {
          totals[columnIndex] += value;
        }
      }

      if (column.key === "#") {
        return index + 1;
      }

      return renderColumnValue(column, item);
    })
  );

  const columnContentTypes: ("text" | "numeric")[] = columns.map(
    (column: Column) => {
      if (column.type === "currency" || column.type === "number") {
        return "numeric";
      } else {
        return "text";
      }
    }
  );

  const headings = columns.map((column: Column) => column.name || "");
  const totalsFormatted: any[] | undefined = !isTotalsDisabled
    ? columns.map((column: Column, index: number) => {
        if (column.isDisabledTotal === true) return "";
        if (column.type === "currency") {
          return (
            <Currency value={totals[index]} isSymbol={true} isDecimal={true} />
          );
        }
        if (column.type === "number") {
          return <Currency value={totals[index]} />;
        }
        if (column.key === "#") {
          return collection.length;
        }
        return "";
      })
    : undefined;

  if (isMobile) {
    return (
      <Fragment>
        {collection.map((item: any, index: number) => {
          const topLeftColumn = columns.find(
            (col: Column) => col.mobileView === "topLeft"
          );
          const topRightColumn = columns.find(
            (col: Column) => col.mobileView === "topRight"
          );
          const bottomLeftColumn = columns.find(
            (col: Column) => col.mobileView === "bottomLeft"
          );
          const bottomRightColumn = columns.find(
            (col: Column) => col.mobileView === "bottomRight"
          );

          return (
            <div
              key={index}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {topLeftColumn
                    ? renderColumnValue(topLeftColumn, item)
                    : null}
                </div>
                <div>
                  {topRightColumn
                    ? renderColumnValue(topRightColumn, item)
                    : null}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div>
                  {bottomLeftColumn
                    ? renderColumnValue(bottomLeftColumn, item)
                    : null}
                </div>
                <div>
                  {bottomRightColumn
                    ? renderColumnValue(bottomRightColumn, item)
                    : null}
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }

  return (
    <ShopifyDataTable
      columnContentTypes={columnContentTypes}
      headings={headings}
      rows={tableData}
      totalsName={{
        singular: "",
        plural: "",
      }}
      totals={totalsFormatted}
    />
  );
};

export default DataTable;
