import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "configureStore";
import {
  Layout,
  Page,
  Card,
  Text,
  BlockStack,
  Spinner,
  FormLayout,
  TextField,
  Form,
  Button,
  InlineStack,
  TextContainer,
  Checkbox,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchCompanyDetails,
  setDestroy,
} from "containers/settings/settings-container.slice";
import {
  updateMicrosoftSettings,
  updateFinvaldaSettings,
  updateDokobitSettings,
  updateFinanceSettings,
} from "services/company.service";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import FileTemplateSelect from "components/file-template-select/file-template-select";
import Addresses from "components/addresses/Addresses";

function CompanySettings() {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [clientId, setClientId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [secret, setSecret] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // finvalda
  const [finvaldaUser, setFinvaldaUser] = useState("");
  const [finvaldaPass, setFinvaldaPass] = useState("");
  const [finvaldaAddress, setFinvaldaAddress] = useState("");
  const [finvaldaCompanyId, setFinvaldaCompanyId] = useState("");
  const [isFinvaldaSyncActive, setIsFinvaldaSyncActive] = useState(false);
  const [isFinvaldaSubmitting, setIsFinvaldaSubmitting] = useState(false);

  // dokobit
  const [accessKey, setDokobitAccessKey] = useState("");
  const [isDokobitSignActive, setIsDokobitSignActive] = useState(false);
  const [isDokobitSubmitting, setIsDokobitSubmitting] = useState(false);

  // finance
  const [financeTemplate, setFinanceTemplate] = useState("");
  const [isFinanceTemplateActive, setIsFinanceTemplateActive] = useState(false);
  const [isFinanceSubmitting, setIsFinanceSubmitting] = useState(false);

  const handleChange = useCallback(
    (newChecked: boolean) => setIsFinvaldaSyncActive(newChecked),
    []
  );

  const dokobitHandleChange = useCallback(
    (newChecked: boolean) => setIsDokobitSignActive(newChecked),
    []
  );

  const financeHandleChange = useCallback(
    (newChecked: boolean) => setIsFinanceTemplateActive(newChecked),
    []
  );

  const loadCompanyData = useCallback(() => {
    if (companyId) {
      dispatch(fetchCompanyDetails({ companyId }));
    }
  }, [companyId, dispatch]);

  const { companyEntity, isLoading } = useSelector(
    (state: RootState) => state.companyContainerReducer
  );

  useEffect(() => {
    loadCompanyData();
    return () => {
      dispatch(setDestroy());
    };
  }, [loadCompanyData]);

  const handleFormSubmit = async () => {
    setIsSubmitting(true);
    const formData = {
      clientId,
      tenantId,
      secret,
    };

    try {
      await updateMicrosoftSettings(formData, companyId);
      // Re-fetch company data after updating
      loadCompanyData();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFinvaldaFormSubmit = async () => {
    setIsFinvaldaSubmitting(true);
    const formData = {
      finvaldaAddress,
      finvaldaUser,
      finvaldaPass,
      finvaldaCompanyId,
      isFinvaldaSyncActive,
    };

    try {
      await updateFinvaldaSettings(formData, companyId);
      // Re-fetch company data after updating
      loadCompanyData();
    } finally {
      setIsFinvaldaSubmitting(false);
    }
  };

  const handleDokobitFormSubmit = async () => {
    setIsDokobitSubmitting(true);
    const formData = {
      accessKey,
      isDokobitSignActive,
    };

    try {
      await updateDokobitSettings(formData, companyId);
      // Re-fetch company data after updating
      loadCompanyData();
    } finally {
      setIsDokobitSubmitting(false);
    }
  };

  const handleFinanceFormSubmit = async () => {
    setIsFinanceSubmitting(true);
    const formData = {
      financeTemplate,
      isFinanceTemplateActive,
    };

    try {
      await updateFinanceSettings(formData, companyId);
      // Re-fetch company data after updating
      loadCompanyData();
    } finally {
      setIsFinanceSubmitting(false);
    }
  };

  // Set form fields with companyEntity values once it's loaded
  useEffect(() => {
    if (companyEntity) {
      setClientId(companyEntity.microsoft?.clientId || "");
      setTenantId(companyEntity.microsoft?.tenantId || "");
      setSecret(companyEntity.microsoft?.secret || "");
      setFinvaldaUser(companyEntity.finvaldaUser);
      setFinvaldaPass(companyEntity.finvaldaPass);
      setFinvaldaAddress(companyEntity.finvaldaAddress);
      setFinvaldaCompanyId(companyEntity.finvaldaCompanyId);
      setIsFinvaldaSyncActive(companyEntity.isFinvaldaSyncActive);
      setDokobitAccessKey(companyEntity.dokobit?.accessKey || "");
      setIsDokobitSignActive(companyEntity.dokobit?.isActive || false);
      setFinanceTemplate(companyEntity.financeTemplate || "");
      setIsFinanceTemplateActive(
        companyEntity.isFinanceTemplateActive || false
      );
    }
  }, [companyEntity]);

  const handleEditClick = (id: string) => {
    dispatch(setModalxComponent("CompanyForm"));
    dispatch(
      setModalxComponentProps({
        id,
      })
    );
  };

  if (!companyEntity) {
    return null;
  }

  return (
    <Page
      fullWidth
      title={companyEntity.name}
      titleMetadata={isLoading && <Spinner size="small" />}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <InlineStack align="end">
                <Button
                  variant="plain"
                  onClick={() => handleEditClick(companyEntity.id)}
                  accessibilityLabel="Preview"
                >
                  {t("edit")}
                </Button>
              </InlineStack>
              <TextContainer>
                <Text as="p" variant="bodyMd">
                  <strong>{t("name")}:</strong> {companyEntity?.name}
                </Text>
                {companyEntity.legalName ? (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("legal_name")}:</strong>{" "}
                    {companyEntity.legalName}
                  </Text>
                ) : (
                  <Text as="p" variant="bodyMd">
                    {t("no_legal_name_provided")}
                  </Text>
                )}
                {companyEntity.vat ? (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("vat")}:</strong> {companyEntity.vat}
                  </Text>
                ) : (
                  <Text as="p" variant="bodyMd">
                    {t("no_vat_provided")}
                  </Text>
                )}
                {companyEntity.code ? (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("code")}:</strong> {companyEntity.code}
                  </Text>
                ) : (
                  <Text as="p" variant="bodyMd">
                    {t("no_code_provided")}
                  </Text>
                )}
                {companyEntity.address ? (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("address")}:</strong> {companyEntity.address}
                  </Text>
                ) : (
                  <Text as="p" variant="bodyMd">
                    {t("no_address_provided")}
                  </Text>
                )}
                {companyEntity.description ? (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("description")}:</strong>{" "}
                    {companyEntity.description}
                  </Text>
                ) : (
                  <Text as="p" variant="bodyMd">
                    {t("no_description_provided")}
                  </Text>
                )}
              </TextContainer>
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <div>
                <Text as="p" fontWeight="bold" alignment="center">
                  {t("Dokobit-Connect")}
                </Text>
                <Form onSubmit={handleDokobitFormSubmit}>
                  <FormLayout>
                    <TextField
                      label={t("dokobit_acess_key")}
                      id="dokobitKey"
                      name="DokobitKey"
                      value={accessKey}
                      onChange={setDokobitAccessKey}
                      autoComplete="off"
                      requiredIndicator={true}
                    />
                    <Checkbox
                      label={t("is_active")}
                      checked={isDokobitSignActive}
                      onChange={dokobitHandleChange}
                    />
                    <Button
                      onClick={handleDokobitFormSubmit}
                      disabled={!accessKey}
                      loading={isDokobitSubmitting}
                    >
                      {t("save")}
                    </Button>
                  </FormLayout>
                </Form>
              </div>
            </Card>
            <Card roundedAbove="sm">
              <div>
                <Text as="p" fontWeight="bold" alignment="center">
                  {t("Finance")}
                </Text>
                <Form onSubmit={handleDokobitFormSubmit}>
                  <FormLayout>
                    <FileTemplateSelect
                      onChange={setFinanceTemplate}
                      value={financeTemplate}
                      isRequired={true}
                      viewAll={true}
                    />
                    <Checkbox
                      label={t("is_active")}
                      checked={isFinanceTemplateActive}
                      onChange={financeHandleChange}
                    />
                    <Button
                      onClick={handleFinanceFormSubmit}
                      disabled={!financeTemplate}
                      loading={isFinanceSubmitting}
                    >
                      {t("save")}
                    </Button>
                  </FormLayout>
                </Form>
              </div>
            </Card>
            <Card roundedAbove="sm">
              <div>
                <Text as="p" fontWeight="bold" alignment="center">
                  Microsoft-Connect
                </Text>
                <Form onSubmit={handleFormSubmit}>
                  <FormLayout>
                    <TextField
                      label={t("client_id")}
                      id="client_id"
                      name="client_id"
                      value={clientId}
                      onChange={setClientId}
                      autoComplete="off"
                      requiredIndicator={true}
                    />
                    <TextField
                      label={t("tenant_id")}
                      id="tenant_id"
                      name="tenant_id"
                      value={tenantId}
                      onChange={setTenantId}
                      autoComplete="off"
                      requiredIndicator={true}
                    />
                    <TextField
                      label={t("secret")}
                      id="secret"
                      name="secret"
                      value={secret}
                      onChange={setSecret}
                      autoComplete="off"
                      requiredIndicator={true}
                    />
                    <Button
                      onClick={handleFormSubmit}
                      disabled={!clientId || !tenantId || !secret}
                      loading={isSubmitting}
                    >
                      {t("save")}
                    </Button>
                  </FormLayout>
                </Form>
              </div>
            </Card>
            <Card roundedAbove="sm">
              <div>
                <Text as="p" fontWeight="bold" alignment="center">
                  {t("finvalda")}
                </Text>
                <Form onSubmit={handleFinvaldaFormSubmit}>
                  <FormLayout>
                    <TextField
                      label={t("finvaldaUser")}
                      id="finvaldaUser"
                      name="finvaldaUser"
                      value={finvaldaUser}
                      onChange={setFinvaldaUser}
                      autoComplete="off"
                      requiredIndicator={true}
                    />
                    <TextField
                      label={t("finvaldaPass")}
                      id="finvaldaPass"
                      name="finvaldaPass"
                      value={finvaldaPass}
                      onChange={setFinvaldaPass}
                      autoComplete="off"
                      requiredIndicator={true}
                    />
                    <TextField
                      label={t("finvaldaAddress")}
                      id="finvaldaAddress"
                      name="finvaldaAddress"
                      value={finvaldaAddress}
                      onChange={setFinvaldaAddress}
                      autoComplete="off"
                    />
                    <TextField
                      label={t("finvaldaCompanyId")}
                      id="finvaldaCompanyId"
                      name="finvaldaCompanyId"
                      value={finvaldaCompanyId}
                      onChange={setFinvaldaCompanyId}
                      autoComplete="off"
                    />
                    <Checkbox
                      label="syncActive"
                      checked={isFinvaldaSyncActive}
                      onChange={handleChange}
                    />
                    <Button
                      onClick={handleFinvaldaFormSubmit}
                      disabled={!finvaldaUser || !finvaldaPass}
                      loading={isFinvaldaSubmitting}
                    >
                      {t("save")}
                    </Button>
                  </FormLayout>
                </Form>
              </div>
            </Card>
            <Addresses 
              addresses={companyEntity?.addresses || []} 
              subjectId={companyId || ""} 
              subject="company"
            />
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default CompanySettings;
