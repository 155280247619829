import { useState, useEffect, useCallback } from "react";
import { API_URL } from "../../../constants";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  FormLayout,
  Banner,
  Form,
  Button,
  TextField,
  Select,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import FileTemplateSelect from "components/file-template-select/file-template-select";
import { setIsModalxOpen } from "components/modalx/modalx.slice";
import { CustomField } from "types/CustomField";
import DatePickerx from "features/datepicker/date-picker";
import { Product } from "types/Product";
import { useAppDispatch } from "hooks";
import { fetchFileTemplateById } from "containers/files-template-form/files-template-form.service";
import ProductsSelect from "components/products-select/ProductsSelect";
import { fetchFileDetails } from "containers/file/file.Slice";
import { Address } from "types/Address";
import AddressSelectMultiple from "features/customer-address-select-multiple/customer-address-select-multiple";

interface onSubmitData {
  templateId: string;
  date: string;
  customFields: { [key: string]: string };
  products: Product[];
  fileId?: string;
  signersInfo?: {};
  address?: String[]
}

interface PostData extends onSubmitData {
  customerId: string;
}

function BuildPdfForm({
  customerId,
  isCustomerApproved = false,
  template = "",
  fileId = "",
  signersInfo,
}: {
  customerId: string;
  isCustomerApproved: boolean;
  template: string;
  fileId: string;
  signersInfo: any;
}) {
  const [templateId, setTemplateId] = useState("");
  const [date, setDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const isFormValid = () => {
    // Check if templateId is missing
    if (!templateId) {
      console.log("Form is invalid: Missing templateId");
      return false;
    }

    if (!date) {
      console.log("Form is invalid: Missing date");
      return false;
    }

    // Check custom fields
    const invalidFields = customFields.filter(
      (field) => field.required && (!field.value || field.value.length === 0)
    );

    if (invalidFields.length > 0) {
      console.log(
        "Form is invalid: The following fields are missing or incomplete:"
      );
      invalidFields.forEach((field) => {
        console.log(
          `- Field name: ${field.name || "Unnamed Field"}, Type: ${field.type}`
        );
      });
      return false;
    }

    // If everything is valid
    return true;
  };

  const fetchCustomFields = useCallback(
    async (templateId: string) => {
      try {
        const fileTemplateEntity = await fetchFileTemplateById(
          companyId,
          templateId
        );
        setCustomFields(
          fileTemplateEntity.customFields.map((field: CustomField) => ({
            ...field,
          }))
        );
      } catch (e) {
        console.error(e);
      }
    },
    [companyId]
  );

  useEffect(() => {
    if (templateId) {
      fetchCustomFields(templateId);
    }
  }, [templateId, fetchCustomFields]);

  const handleCustomFieldChange = (index: number, value: string) => {
    const updatedFields = customFields.map((field, i) =>
      i === index ? { ...field, value } : field
    );
    setCustomFields(updatedFields);
  };

  const handleFormSubmit = async () => {
    if (!isFormValid()) {
      return;
    }
    const formData: PostData = {
      date,
      templateId,
      customerId,
      customFields: {},
      products,
      fileId,
      signersInfo,
    };
    customFields.forEach((field) => {
      formData.customFields[field.label] = field.value;
    });

    if (companyId) {
      try {
        setIsLoading(true);
        const fileEntity = await submit(companyId, formData);
        if (fileId) dispatch(fetchFileDetails({ fileId, companyId }));
        if (fileEntity.id) {
          dispatch(fetchCustomer({ companyId, customerId }));
          setIsSuccessful(true);
          setTimeout(() => {
            dispatch(setIsModalxOpen(false));
          }, 1500);
        } else {
          setIsFailed(true);
          setIsLoading(false);
        }
      } catch (e) {
        setIsFailed(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="user-form">
      <Form onSubmit={handleFormSubmit}>
        <div className="form-warnings">
          {isFailed && (
            <div className="">
              <Banner tone="critical">{t("error")}</Banner>
            </div>
          )}
          {isSuccessful && (
            <div className="">
              <Banner tone="info">{t("ok")}</Banner>
            </div>
          )}
        </div>
        <FormLayout>
          <FileTemplateSelect
            onChange={setTemplateId}
            value={templateId}
            isRequired={true}
            isCustomerApproved={isCustomerApproved}
            templateId={template || ""}
          />
          {templateId && <DatePickerx
            disableDatesAfter={null}
            title={t("document_date")}
            isRequired={true}
            onChange={(value: any) =>
              setDate(value.toISOString().split("T")[0])
            }
            selected={date}
          />}
          {customFields.map((field, index) => (
            <div key={field.id}>
              {field.type === "string" && (
                <TextField
                  label={field.name}
                  value={field.value}
                  autoComplete="off"
                  onChange={(value: string) =>
                    handleCustomFieldChange(index, value)
                  }
                  requiredIndicator={field.required}
                />
              )}
              {field.type === "textarea" && (
                <TextField
                  label={field.name}
                  value={field.value}
                  multiline={4}
                  autoComplete="off"
                  onChange={(value: string) =>
                    handleCustomFieldChange(index, value)
                  }
                  requiredIndicator={field.required}
                />
              )}
              {field.type === "date" && (
                <DatePickerx
                  disableDatesAfter={null}
                  title={field.name}
                  isRequired={field.required}
                  onChange={(value: any) =>
                    handleCustomFieldChange(
                      index,
                      value.toISOString().split("T")[0]
                    )
                  }
                  selected={field.value}
                />
              )}
              {field.type === "select" && (
                <Select
                  label={field.name}
                  options={[
                    {
                      label: t(
                        "files_template_custom_type_select_select_an_option"
                      ),
                      value: "",
                    },
                    ...field.options.map((option) => ({
                      label: option,
                      value: option,
                    })),
                  ]}
                  value={field.value}
                  onChange={(value: string) =>
                    handleCustomFieldChange(index, value)
                  }
                  requiredIndicator={field.required}
                />
              )}
              {field.type === "products" && (
                <ProductsSelect
                  onChange={(value: any) => {
                    if (JSON.stringify(products) !== JSON.stringify(value)) {
                      handleCustomFieldChange(index, value);
                      setProducts(value);
                    }
                  }}
                />
              )}
              {field.type === "address" && (
                <AddressSelectMultiple 
                  value={Array.isArray(field.value) ? field.value : [field.value]}
                  onChange={(value: any) => {
                      handleCustomFieldChange(index, value);
                  }}
                  customerId={customerId}
                />
              )}
            </div>
          ))}
          <Button loading={isLoading} disabled={!isFormValid()} submit>
            {t("save")}
          </Button>
        </FormLayout>
      </Form>
    </div>
  );
}

export default BuildPdfForm;

function submit(companyId: string, data: PostData) {
  const url = `${API_URL}/${companyId}/files/build-pdf`;
  return axios.post(url, data).then((response) => response.data);
}
