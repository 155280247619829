import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ResourceList,
  ResourceItem,
  Text,
  Button,
  Icon,
  Badge,
  Card,
  InlineStack,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import {
  setModalxComponent,
  setModalxComponentProps,
  setModalxStyle,
} from "components/modalx/modalx.slice";
import { API_URL } from "../../constants";
import PrettyDate from "components/format/PrettyDate";
import { EditIcon, ViewIcon, CircleDownIcon } from "@shopify/polaris-icons";
import { hasPdfExtension } from "utils/helperFunctions";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import ReplaceFile from "features/replace-file/replace-file";

function Files({ files, customerId = "", redirect = true, parentTemplateId='' }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companyId, customerId: paramsCustomerId } = useParams();
  const navigate = useNavigate();

  const handleOnClick = (fileId) => {
    dispatch(setModalxComponent("FileForm"));
    dispatch(
      setModalxComponentProps({
        fileId,
        companyId,
      })
    );
  };

  const handlePDFModal = (id) => {
    dispatch(setModalxComponent("PdfView"));
    dispatch(setModalxStyle("large"));
    dispatch(
      setModalxComponentProps({
        id,
        companyId,
      })
    );
  };

  const handleSignRequest = (fileId, relatedTo) => {
    dispatch(setModalxComponent("FileSignAssignPopup"));
    dispatch(
      setModalxComponentProps({
        fileId,
        companyId,
        customerId: customerId,
        relatedTo,
      })
    );
  };

  const handleUploadClick = async (
    id,
    contactSigner,
    companyUserSigner,
    customerId,
    nontechuser,
    signType
  ) => {
    dispatch(setModalxComponent("SignRequestConfirmPopup"));
    dispatch(
      setModalxComponentProps({
        fileId: id,
        contactSigner,
        companyUserSigner,
        customerId,
        nontechuser,
        signType,
      })
    );
  };

  const handleCancelSignerRequest = async (
    fileId,
    contactSigner,
    companyUserSigner,
    customerId,
    nontechuser,
    signType) => {
    dispatch(setModalxComponent("SignRequestCancelPopup"));
    dispatch(
      setModalxComponentProps({
        fileId,
        contactSigner,
        companyUserSigner,
        customerId,
        nontechuser,
        signType,
      })
    );
  };

  const HandleAddSigner = async (
    fileId,
    customerId
  ) => {
    dispatch(setModalxComponent("FileSignAdd"));
    dispatch(
      setModalxComponentProps({
        fileId,
        companyId,
        customerId,
        // relatedTo
      })
    );
  };

  const handleEditFile = (
    customerId,
    templateId,
    fileId,
    assignedUserId,
    id
  ) => {
    dispatch(setModalxComponent("BuildPdfFormWithSigners"));
    dispatch(
      setModalxComponentProps({ customerId, template: templateId, fileId, assignedUserId, id })
    );
  };

  return (
    <ResourceList
      resourceName={{ singular: t("file"), plural: t("files") }}
      items={files}
      renderItem={(item) => {
        const {
          id,
          createdAt,
          title,
          name,
          category,
          user,
          series,
          number,
          categories,
          filesTemplateCustomField,
          isApproved,
          fileId,
          isSigned,
          relatedTo,
          signType,
          dokobit,
          userSigner,
          contactSigner,
          permissionUserSigner,
          templateId,
          customer
        } = item;
        const downloadUrl = `${API_URL}/${companyId}/files/download/${id}`;
        const categoryId =
          categories && categories.length ? categories[0].id : null;
        const fileViewUrl = categoryId
          ? `/app/${companyId}/documents/${categoryId}/${id}`
          : null;
        const isApprovalRequired =
          filesTemplateCustomField?.templateEntity?.isApprovalRequired;
        const customerContactSigned = dokobit ? dokobit?.contactSigner : "";
        const userSigned = dokobit ? dokobit?.userSigner : "";
        const userSigned_ = dokobit ? dokobit?.userSigner_ : "";
        const isDokobitDocument = signType === "Dokobit";
        const canCancelRequest = dokobit ? dokobit.canCancelRequest : true;
        const canMakeNewRequest = dokobit ? dokobit.canMakeNewRequest : true;
        const customerByFetch = customer?.length ? customer[0].id : '';
        return (
          <ResourceItem
            id={id}
            onClick={() =>
              fileViewUrl && redirect ? navigate(fileViewUrl) : ""
            }
          >
            <div className="flex justify-space content_gap">
              <div>
                <div className="content_break">
                  <Text variant="bodyMd" fontWeight="bold" as="h3">
                    [{category?.title || "-"}] - {name || title}
                  </Text>
                </div>
                <div className="content_break content_margin">
                  <Text variant="bodyMd" as="p">
                    {t("series")} : {series?.title || "-"}-{number || "-"}
                  </Text>
                </div>
                <PrettyDate dateString={createdAt} showTime={true} />{" "}
                {user?.name}
                {isDokobitDocument && customerContactSigned && (
                  <div className="content_break content_margin">
                    <Text variant="bodyMd" as="p">
                      {t("sign_status")} : {customerContactSigned.name}{" "}
                      {customerContactSigned?.signed ? (
                        <>
                          <PrettyDate
                            dateString={customerContactSigned.signing_time}
                            showTime={true}
                          />
                          <Badge tone="success">{t("signed")}</Badge>{" "}
                        </>
                      ) : (
                        <>
                          <PrettyDate
                            dateString={dokobit.createdAt}
                            showTime={true}
                          />
                          <Badge tone="attention">{t("pending")}</Badge>
                        </>
                      )}
                    </Text>
                  </div>
                )}
                {isDokobitDocument && userSigned && (
                  <div className="content_break content_margin">
                    <Text variant="bodyMd" as="p">
                      {t("sign_status")} : {userSigned.name}
                      {userSigned?.signed ? (
                        <>
                          <PrettyDate
                            dateString={userSigned.signing_time}
                            showTime={true}
                          />{" "}
                          <Badge tone="success">{t("signed")}</Badge>{" "}
                        </>
                      ) : (
                        <>
                          <PrettyDate
                            dateString={dokobit.createdAt}
                            showTime={true}
                          />
                          <Badge tone="attention">{t("pending")}</Badge>
                        </>
                      )}
                    </Text>
                  </div>
                )}
                {isDokobitDocument && userSigned_ && (
                  <div className="content_break content_margin">
                    <Text variant="bodyMd" as="p">
                      {t("sign_status")} : {userSigned_.name}
                      {userSigned_?.signed ? (
                        <>
                          <PrettyDate
                            dateString={userSigned_.signing_time}
                            showTime={true}
                          />{" "}
                          <Badge tone="success">{t("signed")}</Badge>{" "}
                        </>
                      ) : (
                        <>
                          <PrettyDate
                            dateString={dokobit.createdAt}
                            showTime={true}
                          />
                          <Badge tone="attention">{t("pending")}</Badge>
                        </>
                      )}
                    </Text>
                  </div>
                )}
                {!templateId &&
                  customerId &&
                  !isSigned &&
                  hasPdfExtension(title) &&
                  fileId &&
                  !item?.dokobit?.fileToken &&
                  signType != "Manual" && (
                    <div style={{ marginTop: "5px" }}>
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSignRequest(id, relatedTo);
                        }}
                      >
                        {t("sign_request")}
                      </Button>
                    </div>
                  )}
                <InlineStack gap={100}>
                  {customerId &&
                    contactSigner &&
                    templateId &&
                    !isSigned &&
                    signType === "Dokobit" && canMakeNewRequest && (
                      <div style={{ marginTop: "5px" }}>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            handleUploadClick(
                              id,
                              contactSigner,
                              permissionUserSigner,
                              customerId,
                              userSigner,
                              signType
                            );
                          }}
                        >
                          {t("sign_request")}
                        </Button>
                      </div>
                    )}
                </InlineStack>
                {customerId && item.signType === "Manual" && !isSigned && (
                  <ReplaceFile
                    title={t("upload_signed_document")}
                    onChange={() => ""}
                    subject="customer"
                    parentFileId={id}
                  />
                )}
                <InlineStack gap={200}>
                  {isApprovalRequired && !isApproved && (
                    <div style={{ marginTop: "8px" }}>
                      <Badge tone="attention">{t("approval_required")}</Badge>
                    </div>
                  )}

                  {isSigned && (
                    <div style={{ marginTop: "8px" }}>
                      <Badge tone="success">{t("signed")}</Badge>
                    </div>
                  )}
                </InlineStack>
              </div>
              <div className="layout-column buttons_gap">
              {templateId && (customerId || paramsCustomerId) && !isSigned ?
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEditFile(
                      customerId || paramsCustomerId,
                      parentTemplateId,
                      relatedTo,
                      '',
                      id
                    );;
                  }}
                  disabled={isSigned}
                >
                  <Icon
                    source={EditIcon}
                    tone="base"
                    accessibilityLabel={t("edit")}
                  />
                </Button>
                :
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOnClick(id);
                  }}
                  disabled={isSigned}
                >
                  <Icon
                    source={EditIcon}
                    tone="base"
                    accessibilityLabel={t("edit")}
                  />
                </Button>
                }
                <Button
                  disabled={hasPdfExtension(title) && fileId ? false : true}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent parent click
                    handlePDFModal(id);
                  }}
                >
                  <Icon
                    source={ViewIcon}
                    tone="base"
                    accessibilityLabel={t("view")}
                  />
                </Button>
                <Button
                  url={downloadUrl}
                  target="_blank"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  disabled={!fileId}
                >
                  <Icon
                    source={CircleDownIcon}
                    tone="base"
                    accessibilityLabel={t("download")}
                  />
                </Button>
              </div>
            </div>
          </ResourceItem>
        );
      }}
    />
  );
}

export default Files;

Files.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array.isRequired,
  isCatRequired: PropTypes.bool,
};

Files.defaultProps = {
  isCatRequired: false,
};
