import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "configureStore";
import { Button, InlineStack, Banner } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { setDestroy, setIsModalxOpen } from "components/modalx/modalx.slice";
import { useParams } from "react-router-dom";
import { assignFileCategoryPermissionToUser } from "services/files-category.service";
import { fetchCategories } from "containers/document/category-permissions-tree/category-tree-permission.Slice";

interface ConfirmModalProps {
    user: string;
    category: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ user, category }) => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const { companyId } = useParams();

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleToSendRequest = async () => {
        try {
            setIsLoading(true);
            const payload = {
                user, 
                category
            }
            await assignFileCategoryPermissionToUser(companyId, payload);
            if(companyId && user){
                dispatch(fetchCategories({ companyId, userId:user }));
            }
            setSuccessMessage("ok");
            setIsLoading(false);
            closeModal()
        } catch (e) {
            setErrMsg("error");
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        dispatch(setIsModalxOpen(false));
        dispatch(setDestroy())
    };

    return (
        <>
            <div className="form-warnings">
                {errMsg && (
                    <div>
                        <Banner tone="critical">{t(errMsg)}</Banner>
                    </div>
                )}
                {successMessage && (
                    <div>
                        <Banner tone="info">{t(successMessage)}</Banner>
                    </div>
                )}
            </div>
            <h1 style={{ textAlign: 'center' }}>{t('files_category_add_remove?')}</h1>

            <InlineStack align="space-between">
                <Button onClick={handleToSendRequest} loading={isLoading}>
                    {t("Yes")}
                </Button>

                <Button onClick={closeModal}>
                    {t("No")}
                </Button>
            </InlineStack>
        </>
    );
};

export default ConfirmModal;