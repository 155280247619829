import axios from "axios";
import { API_URL } from "constants";

export const addSigner = async (companyId, payload) => {
  const url = `${API_URL}/${companyId}/dokobit/add-signer`;
  return axios.post(url, payload).then((response) => response.data);
};

export const removeSigner = async (companyId, payload) => {
  const url = `${API_URL}/${companyId}/dokobit/remove-signer`;
  return axios.post(url, payload).then((response) => response.data);
};